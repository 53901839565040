import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SanaButton } from 'components/primitives/buttons';
import PrintHeaderTemplate from 'components/sections/header/PrintHeaderTemplate';
import { Placeholder } from 'components/primitives/placeholders';
import { stringifyQuery, parseQuery } from 'utils/url';
import { navigateTo } from 'behavior/events';

const PrintControls = ({ location, routeData, hideCloseButton=false, navigateTo }) => {
  const disablePrintMode = () => {
    const query = parseQuery(location.search);
    delete query.print;
    delete query.uomId;
    delete query.variantId;
    const url = location.pathname + stringifyQuery(query);

    navigateTo(routeData, url, { replaceHistory: true });
  };

  const escapePrintMode = useCallback(e => {
    if (e.keyCode === 27 && !hideCloseButton)
      disablePrintMode();
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escapePrintMode);

    return () => {
      document.removeEventListener('keydown', escapePrintMode);
    };
  }, []);

  const printCloseBtnPlaceholder = <Placeholder className={`${btnStyles.btnBig} ${btnStyles.printClose} ${btnStyles.printCloseBtnPlaceholder}`} />;
  const printBtnPlaceholder = <Placeholder className={`${btnStyles.btnBig} ${btnStyles.printBtnPlaceholder}`} />;

  return (
    <>
      <div className={btnStyles.btnPrintControl}>
        <SanaButton textKey="Print"
          className={`${btnStyles.btnBig} ${btnStyles.btn} ${btnStyles.btnAction} ${btnStyles.print}`}
          onClick={(handlePrint)}
          placeholder={printBtnPlaceholder}
          autoFocus
        />
        {!hideCloseButton && 
          <SanaButton textKey="Close"
            className={`${btnStyles.btnBig} ${btnStyles.btn}`}
            onClick={disablePrintMode}
            placeholder={printCloseBtnPlaceholder}
          />}
      </div>
      <div id="header">
        <PrintHeaderTemplate />
      </div>
    </>
  );
};

PrintControls.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }),
  routeData: PropTypes.object,
  hideCloseButton: PropTypes.bool,
  navigateTo: PropTypes.func.isRequired,
};

const mapStateToProps = ({ 
  routing: { 
  location, 
  routeData,
  },
  page: {
    hideCloseButton,
  },
}) => ({ location, routeData, hideCloseButton });

export default connect(mapStateToProps, { navigateTo })(PrintControls);

function handlePrint() {
  window.print();
}
