import { ofType, combineEpics } from 'redux-observable';
import { switchMap, map } from 'rxjs/operators';
import { compressCallOffBodyLines, addDummyConfigLines } from 'components/objects/scheduleCallOffOrder/utils';
import {
  CALLOFF_BEGIN,
	beginCallOffResponse,
  CALLOFF_SUBMIT_REQUEST,
  callOffSubmitResponse,
  CALLOFF_HEADER_REQUEST,
  callOffHeaderResponse,
  CALLOFF_BODY_REQUEST,
  callOffBodyResponse,
  CALLOFF_SEND_EMAIL_REQUEST,
  sendCallOffEmailResponse,
  CALLOFF_SUBMIT_SCHEDULE,
  submitCallOffScheduleResponse,
  CALLOFF_SCHEDULE_SUBMIT_STEP_1,
  submitCallOffScheduleStep1Response,
} from './actions';
import { 
  submitCallOffMutation,
  getCallOffHeader,
  getCallOffBody,
  sendCallOffAuthCodeMutation,
  submitScheduleMutation,
	getProjectContacts,
} from './queries';

const begin$ = (action$, _state$, { api }) => {
	return action$.pipe(
		ofType(CALLOFF_BEGIN),
		switchMap(({ payload }) => 
			api.graphApi(getProjectContacts, payload.projectId)
				.pipe(map(d => beginCallOffResponse(d)))),
	);
};

const submit$ = (action$, _state$, { api }) => {
  return action$.pipe(
    ofType(CALLOFF_SUBMIT_REQUEST),
    switchMap(({ payload }) =>
      api.graphApi(submitCallOffMutation, { 
        input: {
          contactNos: payload.contactNos,
          callOffType: payload.callOffType,
          selectedShippingAddress: payload.selectedShippingAddress,
          referenceNo: payload.referenceNo
        },
      }).pipe(
        map(d => {
          return callOffSubmitResponse(d.callOff.submitCallOff);
        }),
      ),
    ),
  );
};

const sendEmail$ = (action$, _state$, { api }) => {
  return action$.pipe(
    ofType(CALLOFF_SEND_EMAIL_REQUEST),
    switchMap(({ payload }) =>
      api.graphApi(sendCallOffAuthCodeMutation, {
        input: {
          callOffId: payload.callOffId,
          emailAddress: payload.emailAddress,
        },
      }).pipe(
        map(() => {
          return sendCallOffEmailResponse();
        }),
      ),
    ),
  );
};

const getHeader$ = (action$, _state$, { api }) => {
  return action$.pipe(
    ofType(CALLOFF_HEADER_REQUEST),
    switchMap(({ payload }) =>
      api.graphApi(getCallOffHeader, { 
        callOffId: payload.callOffId,
      }).pipe(
        map(d => {
          return callOffHeaderResponse(d.callOff);
        }),
      ),
    ),
  );
};

const getBody$ = (action$, _state$, { api }) => {
  return action$.pipe(
    ofType(CALLOFF_BODY_REQUEST),
    switchMap(({ payload }) =>
      api.graphApi(getCallOffBody, { 
        input: {
          callOffId: payload.callOffId,
          email: payload.email,
          authCode: payload.authCode,
          shipmentDate: payload.shipmentDate,
        },
      }).pipe(
        map(d => {
          // Uncomment in case we want to converge exploded items into one single item
          //d.callOff.body.lines = addDummyConfigLines(d.callOff.body.lines);

          return callOffBodyResponse(d);
        }),
      ),
    ),
  );
};

const submitSchedule$ = (action$, _state$, { api }) => {
  return action$.pipe(
    ofType(CALLOFF_SUBMIT_SCHEDULE),
    switchMap(( { payload }) => 
      api.graphApi(submitScheduleMutation, {
        input: {
          callOffId: payload.callOffId,
          linesToCallOff: payload.linesToCallOff,
					callOffEmail: payload.callOffEmail,
          reference: payload.reference,
        },
      }).pipe(
        map(d => {
          return submitCallOffScheduleResponse(d);
        }),
      ),
    ),
  );
};

const step1Submit$ = (action$, _state$, { api }) => {
  return action$.pipe(
    ofType(CALLOFF_SCHEDULE_SUBMIT_STEP_1),
    switchMap(({ payload }) =>
      api.graphApi(getCallOffBody, { 
        input: {
          callOffId: payload.callOffId,
          email: payload.email,
          authCode: payload.authCode,
          shipmentDate: payload.shipmentDate,
        },
      }).pipe(
        map(d => {
          // Uncomment in case we want to converge exploded items into one single item
          //d.callOff.body.lines = addDummyConfigLines(d.callOff.body.lines);
          
          return submitCallOffScheduleStep1Response(d);
        }),
      ),
    ),
  );
};

export default combineEpics(begin$, submit$, sendEmail$, getHeader$, getBody$, submitSchedule$, step1Submit$);