import { useSelector } from 'react-redux';

export default function useConfigurableButOffline() {
  
  const { products } = useSelector(s => s.page);
  const { product } = useSelector(s => s.page);
  const { offlineMode } = useSelector(s => s.app);

  const isConfigurable = product => {
    const { productConfiguratorInfo: configInfo } = product;
    
    return configInfo!==undefined && configInfo.isProductConfigurable;
  };

  const configurableProduct = product===undefined ?
    products.some(p => isConfigurable(p)) :
    product.productConfiguratorInfo!==undefined && product.productConfiguratorInfo.isProductConfigurable;

  return configurableProduct && offlineMode;
}