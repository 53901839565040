import styles from './Summary.module.scss';
import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import SummaryBase from './SummaryBase';
import { BasketLinkLayoutOption } from './constants';

const MobileBasketSummaryBlock = ({ id, model, isDesignerMode }) => {
  const options = useMemo(() => ({
    compact: true,
    showBasketLinkLabel: false,
    showMiniatureOnBasket: model.showMiniatureOnBasket,
    basketLinkLayout: model.basketLink_ShowNumberOfProductsAsBadge
      ? BasketLinkLayoutOption.ShowNumberOfProductsAsBadge
      : BasketLinkLayoutOption.TextRightOfIcon,
    basketLinkThemeFontSize: model.basketLink_ThemeFontSize,
  }), [model]);
  const summaryId = `Summary_${id}`;

  return (
    <>
      <Helmet>
        <style>{`
          #${summaryId} .${styles.header},
          #${summaryId} .${styles.placeholder} {
            ${model.basketLink_FontFamily ? `font-family: ${model.basketLink_FontFamily}, sans-serif;` : ''}
            background-color: ${model.basketLink_BackgroundColor || 'transparent'};
            color: ${model.basketLink_FontColor || 'inherit'};
          }
          #${summaryId} .${styles.icon} {
            color: ${model.basketLink_IconColor || '#cb2245'};
          }
          #${summaryId} .${styles.badge} {
            background-color: ${model.basketLink_BadgeBackgroundColor || '#e6e6e6'};
            color: ${model.basketLink_FontColor || '#333'};
          }
          .${styles.header}.${styles.fixed} {
            color: ${model.basketLink_FontColor || '#fff'};
            background-color: ${model.basketLink_IconColor || '#cb2245'};
          }
        `}</style>
      </Helmet>
      <SummaryBase id={summaryId} isDesignerMode={isDesignerMode} options={options} />
    </>
  );
};

MobileBasketSummaryBlock.propTypes = {
  id: PropTypes.string.isRequired,
  model: PropTypes.shape({
    showMiniatureOnBasket: PropTypes.bool.isRequired,
    basketLink_ShowNumberOfProductsAsBadge: PropTypes.bool.isRequired,
    basketLink_FontFamily: PropTypes.string,
    basketLink_ThemeFontSize: PropTypes.string.isRequired,
    basketLink_IconColor: PropTypes.string,
    basketLink_BackgroundColor: PropTypes.string,
    basketLink_FontColor: PropTypes.string,
    basketLink_BadgeBackgroundColor: PropTypes.string,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

export default memo(MobileBasketSummaryBlock);
