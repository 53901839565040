export const UPDATE_PRODUCT_CALCULATED_FIELDS = 'PRODUCT/UPDATE/CALCULATED_FIELDS';
export const updateCalculatedFields = options => ({
  type: UPDATE_PRODUCT_CALCULATED_FIELDS,
  payload: { options },
});

export const PRODUCT_CALCULATED_FIELDS_LOADED = 'PRODUCT/CALCULATED_FIELDS/LOADED';
export const productCalculatedFieldsLoaded = product => ({
  type: PRODUCT_CALCULATED_FIELDS_LOADED,
  payload: product,
});

export const REVIEWS_REQUESTED = 'PRODUCT/REVIEWS/REQUESTED';
export const requestReviews = (productId, pageIndex) => ({
  type: REVIEWS_REQUESTED,
  payload: { productId, pageIndex },
});

export const REVIEWS_RECEIVED = 'PRODUCT/REVIEWS/RECEIVED';
export const reviewsReceived = reviews => ({
  type: REVIEWS_RECEIVED,
  payload: reviews,
});

export const REVIEW_SUBMITTED = 'PRODUCT/REVIEW/SUBMITTED';
export const sendReview = data => ({ type: REVIEW_SUBMITTED, payload: data });

export const REVIEW_PROCESSED = 'PRODUCT/REVIEW/PROCESSED';
export const reviewProcessed = success => ({
  type: REVIEW_PROCESSED,
  payload: success,
});

export const VOLUME_PRICES_REQUESTED = 'VOLUME_PRICES/REQUESTED';
export const requestVolumePrices = (productId, variantId, uomId) => ({
  type: VOLUME_PRICES_REQUESTED,
  payload: { productId, variantId, uomId },
});

export const VOLUME_PRICES_RECEIVED = 'VOLUME_PRICES/RECEIVED';
export const volumePriceReceived = volumePrices => ({
  type: VOLUME_PRICES_RECEIVED,
  payload: volumePrices,
});

export const SALES_AGREEMENT_REQUESTED = 'PRODUCT/SALES_AGREEMENT_REQUESTED';
export const requestSalesAgreement = (agreementId, productId) => ({
  type: SALES_AGREEMENT_REQUESTED,
  payload: { agreementId, productId },
});

export const SALES_AGREEMENT_RECEIVED = 'PRODUCT/SALES_AGREEMENT_RECEIVED';
export const receiveSalesAgreement = (productId, agreement, linesAvailability, canViewUom, allowUomSelection, productUom, productUoms) => ({
  type: SALES_AGREEMENT_RECEIVED,
  payload: { productId, agreement, linesAvailability, canViewUom, allowUomSelection, productUom, productUoms },
});

export const SALES_AGREEMENT_PRODUCT_VARIANT_CHANGED = 'PRODUCT/SALES_AGREEMENT_PRODUCT_VARIANT_CHANGED';
export const changeProductVariantForSalesAgreement = (variantId, canViewUom, allowUOMSelection) => ({
  type: SALES_AGREEMENT_PRODUCT_VARIANT_CHANGED,
  payload: { variantId, canViewUom, allowUOMSelection },
});

export const RELATED_ITEMS_DIALOG_ACKNOWLEDGED = 'PRODUCT/RELATED_ITEMS_DIALOG_ACKNOWLEDGED';
export const acknowledgeRelatedItemsDialog = () => ({
  type: RELATED_ITEMS_DIALOG_ACKNOWLEDGED,
});