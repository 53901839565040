import { createReducer } from 'utils/redux';
import { AGREEMENTS_RECEIVED, AGREEMENTS_SEARCH_PRODUCT_IDS_RECEIVED, AGREEMENT_LINES_AVAILABILITY_RECEIVED } from './actions';
import { CONTACT_EDITED } from 'behavior/salesAgreements';

export default createReducer(null, {
  [AGREEMENTS_RECEIVED]: onAgreementsReceived,
  [AGREEMENTS_SEARCH_PRODUCT_IDS_RECEIVED]: onSearchProductIdsReceived,
  [AGREEMENT_LINES_AVAILABILITY_RECEIVED]: onAgreementLinesAvailabilityReceived,
  [CONTACT_EDITED]: onContactEdited,
});

function onAgreementsReceived(state, { payload }) {
  const newAgreements = payload.agreements || [];

  // OSP-502: Duplicate Sales Agreements / Blanket Orders
  // The bug: The AGREEMENTS_RECEIVED reducer was receiving the same payload twice, hence the duplicated records.
  // Ideally, we would need to find the real source of what is sending the same payload twice.
  //
  // Solution: Not the most elegant, but forcing only new sales agreement that are not already in the Redux State fixes the problem.
  let agreementsKeyedById = {};
  let filteredNewArguments = [...newAgreements];
  if (payload && payload.append && state.agreements) {
    for (let agreement of state.agreements.items) {
      agreementsKeyedById[agreement.id] = agreement;
    }
    filteredNewArguments = newAgreements.filter((e)=> (Object.hasOwn(agreementsKeyedById, e.id) === false));
  }

  const items = payload.append && state.agreements
    ? state.agreements.items.concat(filteredNewArguments)
    : filteredNewArguments;
  const agreements = {
    items,
    loadedLength: newAgreements.length,
  };
  return { ...state, agreements };
}

function onSearchProductIdsReceived(state, { payload: search }) {
  return { ...state, search };
}

function onAgreementLinesAvailabilityReceived(state, { payload: availableLines }) {
  if (availableLines.length === 0)
    return state;

  const lines = state.agreement.lines.map(line => {
    const availableLine = availableLines.find(({ lineId }) => lineId === line.id);

    if (!(availableLine?.variantId && line.product && line.product.variants)
      && !line.product?.variants?.some(variant => !variant.isOrderable)
    ) {
      return {
        ...line,
        isLineOrderable: !!availableLine,
      };
    }

    const variantComponentGroups = [];

    line.product.variantComponentGroups.forEach(componentGroup => {
      const components = [];

      componentGroup.components.forEach(component => {
        const variants = component.variants.filter(componentVariantId => {
          const isVariantOrderable = line.product.variants.find(variant => variant.id === componentVariantId).isOrderable;
          return isVariantOrderable && availableLines.some(
            ({ lineId, variantId }) => lineId === line.id && (!variantId || variantId === componentVariantId),
          );
        });

        if (!variants.length)
          return;

        components.push({
          ...component,
          variants,
        });
      });

      if (!components.length)
        return;

      variantComponentGroups.push({
        ...componentGroup,
        components,
      });
    });

    if (!variantComponentGroups.length) {
      return {
        ...line,
        isLineOrderable: false,
      };
    }

    return {
      ...line,
      isLineOrderable: !!availableLine,
      product: {
        ...line.product,
        variantComponentGroups,
      },
    };
  });

  return {
    ...state,
    agreement: {
      ...(state.agreement),
      lines,
    },
  };
}

function onContactEdited(state, { payload }) {

  const { 
    contact: { 
      salesAgreement: {
        editContact: editedContacts,
      },
    },
  } = payload; 

  const editedContactNos = editedContacts.map(({ contactNo }) => contactNo);

  const { 
    agreement: {
      allContacts: existingContacts,
    },
  } = state;

  const newContacts = [
    ...existingContacts.filter(({ contactNo }) => !editedContactNos.includes(contactNo)),
    ...editedContacts,
  ];

  return {
    ...state,
    agreement: {
      ...state.agreement,
      allContacts: newContacts,
    },
  };
}
