import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { MediaGallery } from 'components/objects/mediaGallery';
import { iEquals } from 'utils/helpers';

const ProductGallery = ({ product, images }) => {

  const { id, title, variantId, variantComponents } = product;

  const { product: { noImage: { large: noImageLarge } } } = useSelector(s => s.settings);

  let index = -1;

  const itemsNumber = images.length;
  for (let i = 0; i < itemsNumber; i++) {
    const image = images[i];
    if (image.title == null)
      image.title = title;

    if (index === -1 && image.variantId && iEquals(image.variantId, variantId))
      index = i;
  }

  if (index === -1 && variantComponents != null) {
    const componentValues = Object.values(variantComponents);
    if (componentValues.length)
      index = images.findIndex(image => componentValues.some(v => iEquals(v.id, image.variantId)));
  }

  return <MediaGallery key={id} items={images} noImageLarge={noImageLarge} index={index} />;
};

ProductGallery.propTypes = {
  product: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
};

export default ProductGallery;