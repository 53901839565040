import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Step2SummaryLine from './Step2SummaryLine';
import { Table } from 'components/primitives/table';
import { SimpleText } from 'components/sanaText';

const Step2Summary = ({ linesForCallOff }) => {

  const hasLinesForCallOff = !!linesForCallOff;
  if (!hasLinesForCallOff) {
    return null; 
  }

  const linesWithQty = linesForCallOff.filter(line => line.quantity > 0);
  if (linesWithQty.length === 0) {
    return null;
  }

  return (
    <>
      <h2><SimpleText textKey="ScheduleCallOffOrder_Summary" /></h2>
      <Table className="calloff-schedule-step-2-summary">
        <thead>
          <tr>
            <th className="product-id-header">
              <SimpleText textKey="ProductId" />
            </th>
            <th className="product-title-header">
              <SimpleText textKey="General_Product_Title" />
            </th>
            <th className="quantity-header">
              <SimpleText textKey="Quantity" />
            </th>
          </tr>
        </thead>
        <tbody>
          {linesWithQty.map((line, idx) => (
            <>
              <Step2SummaryLine key={idx} line={line} />
            </>
          ))}
        </tbody>
      </Table>
    </>
  );
};

Step2Summary.propTypes = {
  linesForCallOff: PropTypes.array,
  documentLines: PropTypes.array,
};

export default connect (
 ({
    callOff: {
      linesForCallOff,
    },
 }) => ({ linesForCallOff }),
)(Step2Summary);