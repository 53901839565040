import styles from './PLP.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { connectToContext } from 'utils/react';
import { sanitizeId } from 'utils/helpers';
import ProductContext from './ProductContext';
import { Link } from 'components/primitives/links';
import { WithProductClickTracking } from 'components/objects/analytics';
import { SimpleText } from 'components/sanaText';
import Stock from './Stock';
import Specifications from './Specifications';
import { Placeholder } from 'components/primitives/placeholders';
import { useIsPreview } from 'components/objects/preview';
import WithCatalogueNavigationRoute from './WithCatalogueNavigationRoute';

const Description = ({ id, url, name: title, leadTime, madeToOrder, pageId, isVariant }) => {
  const isPreview = useIsPreview();
  const canViewAvailability = !madeToOrder;

  return (
    <div className={styles.productDescription}>
      {isPreview && !url
        ? <Placeholder className={styles.titlePreviewPlaceholder} />
        : (
          <WithProductClickTracking>
            {handleClick => (
              <WithCatalogueNavigationRoute productFamily={{ pageId, url, isVariant }}>
                {(productUrl, productRouteBuilder) => (
                  <Link 
                    onClick={handleClick} 
                    url={productUrl} 
                    to={productRouteBuilder} 
                    className={styles.productTitle}
                  >
                    <h2 id={sanitizeId('title' + id)}>
                      {title}
                      {/*Use dot for screen readers to add pause after the title.*/}
                      <i className="visually-hidden">.</i>
                    </h2>
                  </Link>
                )}
              </WithCatalogueNavigationRoute>
            )}
          </WithProductClickTracking>
        )
      }
      <div className={styles.productIdStock}>
        <span className={styles.productId}>
          <span className={styles.productIdName}>
            <SimpleText
              textKey="General_Product_Id"
              placeholder={<Placeholder className={styles.productIdNamePlaceholder} />}
            />
          </span>
          <span className={styles.productIdValue}>{id}</span>
          {' '}
        </span>
        {canViewAvailability && leadTime && <div>
          <span className={styles.productId}>
            <span className={styles.productIdValue}>
              <SimpleText
                textKey="General_LeadTime"
              />
            </span>
            {' '}
            <span className={styles.productIdValue}>{leadTime}</span>
            {' '}
            <span className={styles.productIdValue}>
              <SimpleText
                textKey="General_Days"
              />
            </span>
          </span>
        </div>}
        {canViewAvailability && <Stock />}
      </div>
      <Specifications />
    </div>
  );
};

Description.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  isVariant: PropTypes.bool,
  leadTime: PropTypes.number,
  madeToOrder: PropTypes.bool,
};

export default connectToContext([ProductContext],
  ({ id, url, name, leadTime, madeToOrder, pageId, isVariant }) => ({ id, url, name, leadTime, madeToOrder, pageId, isVariant }),
)(
  memo(Description),
);