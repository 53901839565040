import PropTypes from 'prop-types';
import { SanaLinkButton } from 'components/primitives/links';
import { WithProductClickTracking } from 'components/objects/analytics';
import WithProductRoute from './WithProductRoute';

const ViewProductButton = ({
  textKey,
  titleTextKey,
  product,
  className,
  placeholder,
  withoutBackTo,
  ...props
}) => {
  return (
    <WithProductClickTracking>
      {handleClick => (
        <WithProductRoute product={product} withoutBackTo={withoutBackTo}>
          {(productUrl, productRouteBuilder) => (
            <SanaLinkButton className={className}
              url={productUrl}
              to={productRouteBuilder}
              textKey={textKey}
              titleTextKey={titleTextKey}
              placeholder={placeholder}
              onClick={handleClick}
              {...props}
            />
          )}
        </WithProductRoute>
      )}
    </WithProductClickTracking>
  );
};

ViewProductButton.propTypes = {
  textKey: PropTypes.string.isRequired,
  defaultText: PropTypes.string,
  titleTextKey: PropTypes.string,
  product: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.node,
  withoutBackTo: PropTypes.bool,
};

export default ViewProductButton;