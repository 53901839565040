import styles from './HeaderContent.module.scss';
import { Container } from 'components/primitives/grid';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PageContent } from 'components/objects/pages';
import { useSelectedFacetsTitle } from '../hooks';
import { makeRichText } from 'utils/render';
import PageBreadcrumb from 'components/objects/pageBreadcrumb';
import WithCatalogueNavigationRoute from '../WithCatalogueNavigationRoute';
import { Link } from 'components/primitives/links';

const HeaderContent = ({ 
  title, 
  description, 
  backgroundColor, 
  backgroundImage, 
  headerContent,
  breadcrumbPageUrl,
  breadcrumbPageTitle,
  breadcrumbPageId,
  productFamilies,
  productVariants,
  isProductVariantListPage,
 }) => {
  const facetsTitle = useSelectedFacetsTitle();
  const style = {};
  if (backgroundColor)
    style.backgroundColor = backgroundColor;

  if (backgroundImage)
    style.backgroundImage = `url("${backgroundImage}")`;

  const hasBreadcrumb = breadcrumbPageId!==undefined && breadcrumbPageId!=='';

  const variants = productVariants?.variants;
  const firstProductVariant = variants!==undefined && variants.length ? variants[0] : {};

  const  { families } = productFamilies;
  let productFamily = isProductVariantListPage ? 
    families.filter(pf => pf.code===firstProductVariant.productFamilyCode)[0] :
    {};

  productFamily = productFamily===undefined ? '' : productFamily;

  return (
    <>
      {hasBreadcrumb &&
        <div className="catalogue-navigation-breadcrumb">
          <WithCatalogueNavigationRoute productFamily={{ pageId: breadcrumbPageId, url: breadcrumbPageUrl, isVariant: false }}>
            {(productUrl, productRouteBuilder) => (
              <Link url={productUrl} to={productRouteBuilder}>
                <div className="catalogue-navigation-caption">
                  {breadcrumbPageTitle}
                </div>
              </Link>
            )}
          </WithCatalogueNavigationRoute>
        </div>
      }
      <div
        className={`${styles.coloredHeaderDesktop} ${styles.header} ${backgroundImage ? styles.customBg : ''}`}
        style={style}
      >
        <Container className={styles.center}>
          <PageBreadcrumb noTopMargin />
          <div className={styles.combined}>
            <div className={styles.h1Holder}>
              <h1>
                {title}
                {facetsTitle &&
                  <span className={styles.facetsTitle}>
                    {facetsTitle}
                  </span>
                }
              </h1>
            </div>
            {description &&
              <div className={`fr-view ${styles.combinedDescription}`}>
                {makeRichText(description)}
              </div>
            }
          </div>
        </Container>
      </div>
      {isProductVariantListPage  && <div className="product-family-description">{productFamily.description}</div>}

      <PageContent content={headerContent} position="header" />
    </>
  );
};

HeaderContent.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  headerContent: PropTypes.any,
  breadcrumbPageUrl: PropTypes.string,
  breadcrumbPageTitle: PropTypes.string,
  breadcrumbPageId: PropTypes.string,
  productFamilies: PropTypes.object,
  productVariants: PropTypes.object,
  isProductVariantListPage: PropTypes.bool,
};

export default connect(({ page }) => ({
  title: page.pageTitle,
  description: page.description,
  backgroundColor: page.backgroundColor,
  backgroundImage: page.backgroundImage,
  breadcrumbPageUrl: page.breadcrumbPageUrl,
  breadcrumbPageTitle: page.breadcrumbPageTitle,
  breadcrumbPageId: page.breadcrumbPageId,
  productFamilies: page.productFamilies,
  productVariants: page.productVariants,
  isProductVariantListPage: page.isProductVariantListPage,
}))(
  HeaderContent,
);
