exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PageTemplate_plp{padding-top:12px;padding-bottom:12px;max-width:100%!important}@media (min-width:var(--breakpoints_Medium,960)px),print{.PageTemplate_product-list{border-left:1px solid var(--borderColor,#e6e6e6)}.PageTemplate_plp.PageTemplate_hide-left-column .PageTemplate_product-list{-webkit-flex-basis:100%;flex-basis:100%;max-width:100%;border-left:none}.PageTemplate_plp.PageTemplate_hide-left-column .PageTemplate_left-column{display:none}}", ""]);

// exports
exports.locals = {
	"plp": "PageTemplate_plp",
	"product-list": "PageTemplate_product-list",
	"productList": "PageTemplate_product-list",
	"hide-left-column": "PageTemplate_hide-left-column",
	"hideLeftColumn": "PageTemplate_hide-left-column",
	"left-column": "PageTemplate_left-column",
	"leftColumn": "PageTemplate_left-column"
};