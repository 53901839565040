import { RouteName } from 'routes';

const allowedRoutes = new Set([
  RouteName.ProductDetails,
  RouteName.LastViewedProducts,
  RouteName.ProductList,
  RouteName.Search,
  RouteName.ProductsWithCategory,
  RouteName.CatalogueNavigation,
]);

type RouteParams = {
  previewToken: unknown;
}

export function isWidgetAllowed(routeName: RouteName, params: RouteParams): boolean {
  return allowedRoutes.has(routeName) && params?.previewToken == null;
}
