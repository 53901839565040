import { SimpleText } from 'components/sanaText';
import './HelpIcon.css';

const TextHelpIcon = () => {
  return (
    <span className="help-icon-text">
      <SimpleText textKey="HelpIconPlaceholderText" />
    </span>
  );
};

export default TextHelpIcon;