import PropTypes from 'prop-types';
import { WithProductClickTracking } from 'components/objects/analytics';
import { Slider } from 'components/primitives/slider';
import WithCatalogueNavigationRoute from '../../WithCatalogueNavigationRoute';
import styles from '../../PLP.module.scss';
import { Link } from 'components/primitives/links';

export const ThumbnailSlider = ({ images, pageId, pageUrl, isVariant }) => {

  const thumbnails = images===undefined ? 
    [] : 
    images
      .filter(ri => ri.medium)
      .map(ri => {
        const theUrl = ri.url===undefined ? ri.medium : ri.url;
        return (
          <div className="catalogue-navigation-slider-image-container">
            <WithProductClickTracking>
              {handleClick => (
                <WithCatalogueNavigationRoute productFamily={{ pageId, url: pageUrl, isVariant }}>
                  {(productUrl, productRouteBuilder) => (
                    <Link onClick={handleClick} url={productUrl} to={productRouteBuilder} className={styles.productTitle}>
                      <img src={theUrl} className="catalogue-navigation-slider-image" />
                    </Link>
                  )}
                </WithCatalogueNavigationRoute>
              )}
            </WithProductClickTracking>
          </div>
        );
      });
  
  return <Slider items={thumbnails} showArrows={thumbnails.length>1} showDots={false} />;
};

ThumbnailSlider.propTypes = {
  images: PropTypes.array,
  pageId: PropTypes.string,
  pageUrl: PropTypes.string,
  isVariant: PropTypes.bool, 
};

