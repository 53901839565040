import type { Uom, ProductCategories } from './types';
import type { ProductInputData } from './payload.types';
import { ConfigurationResult } from '../productConfigurator';

type ProductInfo = {
  id: string;
  title: string;
  categoriesPaths: Array<{
    categories: ProductCategories;
  }>;
}

type ConfigurationInfo = {
  id: string;
  masterProduct: ProductInfo;
  products: Array<{
    id: string;
    variantId: string | null;
  }>;
}

interface LineInfo {
  uom?: Uom;
  price?: number;
}

interface BaseLine extends LineInfo {
  quantity: number;
  isSupplementary?: boolean;
}

interface VariationLine extends BaseLine {
  variationId: string;
}

interface SimpleProductLine extends BaseLine {
  product: ProductInfo;
}

interface VariantsProductLine extends SimpleProductLine {
  subLines: Array<VariationLine>;
}

interface ConfigurationLine extends BaseLine {
  configuration: ConfigurationInfo;
}

type BasketLine = SimpleProductLine | VariantsProductLine | ConfigurationLine;

const isProductLine = (line: BaseLine): line is SimpleProductLine =>
  'product' in line;

const isVariantsLine = (line: BaseLine): line is VariantsProductLine =>
  !!(line as VariantsProductLine).subLines;

const isConfigurationLine = (line: BaseLine): line is ConfigurationLine =>
  !!(line as ConfigurationLine).configuration;

const isVariationLine = (line: BaseLine): line is VariationLine =>
  'variationId' in line;

interface WishListBaseLine extends LineInfo {
  configuration: ConfigurationInfo | null;
}

interface WishListSimpleLine extends WishListBaseLine {
  product: ProductInfo;
}

interface WishListVariationLine extends WishListBaseLine {
  variationId: string;
}

interface WishListVariantsLine extends WishListSimpleLine {
  subLines: Array<WishListVariationLine>;
}

type WishListLine = WishListSimpleLine | WishListVariantsLine;

const isWishListVariantsLine = (line: WishListLine): line is WishListVariantsLine =>
  !!(line as WishListVariantsLine).subLines;

export const getProductsTrackingDataFromLines = (lines: Array<BasketLine>): Array<ProductInputData> => {
  if (lines == null)
    return [];

  const trackingProducts: Array<ProductInputData> = [];
  lines.forEach(line => {
    if (line.isSupplementary)
      return;

    trackingProducts.push(...getProductsDataFromLine(line));
  });
  return trackingProducts;
};

export const getProductsTrackingDataFromWishListLines = (lines: Array<WishListLine>): Array<ProductInputData> => {
  if (lines == null)
    return [];

  const trackingProducts: Array<ProductInputData> = [];
  lines.forEach(line => {
    trackingProducts.push(...getProductsDataFromWishListLine(line));
  });
  return trackingProducts;
};

export const getConfigurationResultTrackingData = (configurationResult: ConfigurationResult): Array<ProductInputData> => {
  const { masterProduct, products } = configurationResult;
  const productsData = products.map(product => ({
    id: masterProduct.id,
    title: masterProduct.title,
    uom: product.uomId ? { id: product.uomId } : undefined,
    categoriesPaths: masterProduct.categoriesPaths || [],
    variant: product.variantId ? `${product.id}_${product.variantId}` : product.id,
    price: product.price,
    quantity: product.quantity,
  }));

  return productsData;
};

export const getModifiedProductsTrackingData = (oldLines: Array<BasketLine>, modifiedLines: Array<BasketLine>, addedLines?: Array<BasketLine>) => {
  const addedProducts: Array<ProductInputData> = [];
  const removedProducts: Array<ProductInputData> = [];
  if (modifiedLines == null)
    modifiedLines = [];

  modifiedLines.forEach(line => {
    const oldLine = oldLines.find(item => isSameLine(line, item));
    if (oldLine == null) {
      addedProducts.push(...getProductsDataFromLine(line));
      return;
    }

    if (isVariantsLine(line)) {
      line.subLines.forEach(subLine => {
        const oldSubLine = isVariantsLine(oldLine) && oldLine.subLines.find(item => isSameSubline(subLine, item));
        const oldQuantity = oldSubLine ? oldSubLine.quantity : 0;

        if (subLine.quantity > oldQuantity) {
          addedProducts.push(getProductData(line, subLine, subLine.quantity - oldQuantity));
        }
        if (subLine.quantity < oldQuantity) {
          removedProducts.push(getProductData(line, subLine, oldQuantity - subLine.quantity));
        }
      });

      return;
    }

    if (line.quantity > oldLine.quantity) {
      addedProducts.push(getProductData(line, undefined, line.quantity - oldLine.quantity));
    }

    if (line.quantity < oldLine.quantity) {
      removedProducts.push(getProductData(line, undefined, oldLine.quantity - line.quantity));
    }
  });

  if (addedLines && addedLines.length)
    addedProducts.push(...getProductsTrackingDataFromLines(addedLines));

  return {
    addedProducts,
    removedProducts,
  };
};

function isSameLine(line1: BasketLine, line2: BasketLine): boolean {
  if (isConfigurationLine(line1))
    return isConfigurationLine(line2) && line2.configuration.id === line1.configuration.id;

  if (!line1.uom && !line2.uom || isVariantsLine(line1))
    return isProductLine(line2) && line2.product.id === line1.product.id;

  return isProductLine(line2) && line2.product.id === line1.product.id
    && !!line1.uom && !!line2.uom && line1.uom.id === line2.uom.id;
}

function isSameSubline(subLine1: VariationLine, subLine2: VariationLine) {
  if (!subLine1.uom && !subLine2.uom)
    return subLine1.variationId === subLine2.variationId;

  return subLine1.variationId === subLine2.variationId
    && subLine1.uom && subLine2.uom && subLine1.uom.id === subLine2.uom.id;
}

function getProductsDataFromLine(line: BasketLine): Array<ProductInputData> {
  const products: Array<ProductInputData> = [];
  if (isVariantsLine(line)) {
    line.subLines.forEach(subLine => products.push(getProductData(line, subLine)));
  } else {
    products.push(getProductData(line));
  }

  return products;
}

function getProductData(line: SimpleProductLine | ConfigurationLine, subLine?: VariationLine, quantity?: number): ProductInputData {
  const productInfo: ProductInfo = isConfigurationLine(line) ? line.configuration.masterProduct : line.product;
  const lineInfo = subLine || line;
  const lineQuantity = quantity || lineInfo.quantity;
 
  if (isConfigurationLine(lineInfo))
    return createProductData(productInfo, lineInfo, getConfigurationVariant(lineInfo.configuration), lineQuantity);

  if (isVariationLine(lineInfo))
    return createProductData(productInfo, lineInfo, lineInfo.variationId, lineQuantity);

  return createProductData(productInfo, lineInfo, undefined, lineQuantity);
}

function getConfigurationVariant(configuration: ConfigurationInfo): string {
  const product = configuration.products[0];
  return product.variantId ? `${product.id}_${product.variantId}` : product.id;
}

function getProductsDataFromWishListLine(line: WishListLine): Array<ProductInputData> {
  const products = [];
  if (isWishListVariantsLine(line)) {
    line.subLines.forEach(subLine => {
      const productInfo = subLine.configuration ? subLine.configuration.masterProduct : line.product;
      const variant = subLine.configuration ? getConfigurationVariant(subLine.configuration) : subLine.variationId;
      products.push(createProductData(productInfo, subLine, variant));
    });
  } else {
    const productInfo = line.configuration ? line.configuration.masterProduct : line.product;
    const variant = line.configuration ? getConfigurationVariant(line.configuration) : undefined;
    products.push(createProductData(productInfo, line, variant));
  }

  return products;
}

function createProductData(productInfo: ProductInfo, lineInfo: LineInfo, variant?: string, quantity?: number): ProductInputData {
  return {
    id: productInfo.id,
    title: productInfo.title,
    uom: lineInfo.uom,
    categoriesPaths: productInfo.categoriesPaths,
    variant,
    price: lineInfo.price,
    quantity,
  };
}