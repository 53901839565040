import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../PLP.module.scss';
import ProductFamilyLine from './ProductFamilyLine';
import ProductVariantLine from './ProductVariantLine';
import { ProductTrackingContext } from 'components/objects/analytics';
import PropTypes from 'prop-types';
import { Radio } from 'components/primitives/form';
import { useSanaTexts } from 'components/sanaText';
import { 
  catalogueNavigationListViewModeChanged,
} from 'behavior/pages/catalogueNavigation';
import Paging from '../paging';
import { navigateTo } from 'behavior/events';

const ProductFamiliesView = ({ 
  productFamilies,
  productVariants,
  trackingSource,
  gridMode,
  showThumbnails,
  id,
}) => {
  const viewModeFamily = 'family';
  const viewModeAll = 'all';
  const { variants, totalCount: variantsTotalCount } = productVariants;
  const { families, totalCount: familiesTotalCount } = productFamilies;

  const [viewMode, setViewMode] = useState(viewModeFamily);
  const [waitingReset, setWaitingReset] = useState(false);
  const dispatch = useDispatch();

  const { page: { pagingType, reset }, routing: { location: { pathname, routeData }, previous } } = useSelector(s => s);

  if (reset && !waitingReset && previous!==undefined) {
    setWaitingReset(true);
    dispatch(navigateTo(routeData, pathname));
  }

  const [
    viewModeFamilyLbl, 
    viewModeAllLbl,
  ] = useSanaTexts([
    'ProductFamily_ViewMode_FilterByFamily',
    'ProductFamily_ViewMode_ViewAll',
  ]).texts;

  const handleViewModeChanged = ({ target: { value } }) => {
    dispatch(catalogueNavigationListViewModeChanged(value));

    setViewMode(value);
  };

  return (
    <>
      <div className={'family-variant-toggle'}>
        <Radio
          name="viewMode" 
          value={viewModeFamily}
          onChange={handleViewModeChanged}
          checked={viewMode===viewModeFamily}
        >
          <span>{viewModeFamilyLbl}</span>
        </Radio>
        <Radio
          name="viewMode"
          value={viewModeAll}
          onChange={handleViewModeChanged}
          checked={viewMode===viewModeAll}
        >
          <span>{viewModeAllLbl}</span>
        </Radio>
      </div>
      <div className={`${gridMode ? `${styles.gridMode} ` : ''}${styles.list}`} role="list">
        {viewMode===viewModeFamily && productFamilies!==undefined &&
          <>
            {families.map((productFamily, index) => (
            <ProductTrackingContext.Provider key={productFamily.pageId} value={{ productFamily, trackingSource }}>
              <ProductFamilyLine
                productFamily={productFamily}
                gridMode={gridMode}
                showThumbnail={showThumbnails}
                index={index}
              />
            </ProductTrackingContext.Provider>))}
            <Paging pagingType={pagingType} totalCount={familiesTotalCount} />
        </>}
        {viewMode===viewModeAll && productVariants!==undefined && 
        <>
          {variants.map((productVariant, index) => (
            <ProductTrackingContext.Provider key={productVariant.code} value={{ productVariant, trackingSource }}>
              <ProductVariantLine
                productVariant={productVariant}
                gridMode={gridMode}
                showThumbnail={showThumbnails}
                index={index}
              />
            </ProductTrackingContext.Provider>))}
            <Paging pagingType={pagingType} totalCount={variantsTotalCount} />
        </>}
      </div>
    </>
  );
};

ProductFamiliesView.propTypes = {
  productFamilies: PropTypes.object.isRequired,
  productVariants: PropTypes.object.isRequired,
  trackingSource: PropTypes.object,
  gridMode: PropTypes.bool,
  showThumbnails: PropTypes.bool,
  id: PropTypes.string,
};
export default ProductFamiliesView;