import SummaryBase from './SummaryBase';
import { ThemeFontSize } from 'behavior/theme';
import { BasketLinkLayoutOption, FooterLinkStyleOption } from './constants';

const options = {
  showMiniatureOnBasket: false,
  compact: false,
  showBasketLinkLabel: false,
  showExpandIcon: false,
  showThumbnails: true,
  basketLinkLayout: BasketLinkLayoutOption.TextRightOfIcon,
  basketLinkThemeFontSize: ThemeFontSize.Regular,
  headingThemeFontSize: ThemeFontSize.Heading4,
  footerBasketLinkStyle: FooterLinkStyleOption.ShowAsLink,
  footerCheckoutLinkStyle: FooterLinkStyleOption.ShowAsActionButton,
  swapFooterLinksPosition: false,
};

const Summary = () => <SummaryBase options={options} />;

export default Summary;
