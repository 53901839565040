import PropTypes from 'prop-types';
import ProductTitle from './ProductTitle';



const DatasheetProductHeader = ({
  product,
}) => {

  return (
    <div className="datasheet-product-header">
      <ProductTitle product={product} />
    </div>
  );
};

DatasheetProductHeader.propTypes = {
  /**
   * This product a must have a key 'productLine' to display
   * the Reference No.
   */
  product: PropTypes.object.isRequired,
};

export default DatasheetProductHeader;