import { 
  CONTACTS_ADDED, 
  CONTACTS_REMOVED,
  CONTACTS_GET,
  CONTACTS_RECEIVED, 
  CONTACT_EDITED,
  NOTIFICATION_RESENT,
  TOAST_SEEN,
} from './actions';

const initialState = {
  contacts: [],
  contactsLoading: false,
  notificationSent: false,
};

export default function reducer(state = initialState, action) {
  
  if (action.type === CONTACTS_GET)
  {
    return {
      ...state,
      contactsLoading: true,
      notificationSent: false,
    };
  }

  if (action.type === CONTACTS_RECEIVED) {
    return {
      ...state,
      contacts: action.payload.contacts.salesAgreements.agreement.contacts,
      contactsLoading: false,
    };
  }

  if (action.type === CONTACTS_ADDED) {
    return {
      ...state,
      contacts: action.payload.contacts.salesAgreement.addContacts, 
    };
  }

  if (action.type === CONTACTS_REMOVED) {
    return {
      ...state,
      contacts: action.payload.contacts.salesAgreement.removeContacts, 
    };
  }

  if (action.type === CONTACT_EDITED) {
    return {
      ...state,
      contacts: action.payload.contact.salesAgreement.editContact,
    };
  }
  
  if (action.type === NOTIFICATION_RESENT)
  {
    return { 
      ...state,
      notificationSent: true,
    };
  }

  if (action.type === TOAST_SEEN)
  {
    return { 
      ...state,
      notificationSent: false,
    };
  }

  return state;
}
