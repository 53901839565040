import type { Document } from './types';
import { QuoteStatus, DocumentType } from './constants';

export const isDocumentCancelled = (document: Document) =>
  document.status === QuoteStatus.Cancelled || document.status === 'CANCELLED';

export const isDocumentPromotableQuote = (document: Document, documentType: keyof typeof DocumentType) =>
  documentType === DocumentType.Quote && document.status === QuoteStatus.Orderable;

const fieldPerDocumentType = {
  [DocumentType.Order]: 'orders',
  [DocumentType.Quote]: 'quotes',
  [DocumentType.Invoice]: 'invoices',
  [DocumentType.ReturnOrder]: 'returnOrders',
  [DocumentType.CreditNote]: 'creditNotes',
  [DocumentType.ReturnReceipt]: 'returnReceipts',
  [DocumentType.Shipment]: 'shipments',
  // This was added just to conform with TypeScript due to addition of BlanketOrder to DocumentType.
  // The value 'salesAgreementsDetails' and 'salesAgreements' are not linked to anything.
  // I'm not sure if it's the correct values. I just posted set the same name as the routes found in:
  // SDK\Sana.Commerce.WebApp\ClientApp\src\webstore\routes\RouteName.ts
  [DocumentType.BlanketOrder]: 'salesAgreements',
  [DocumentType.CallOff]: 'orders'
} as const;

export function getDocumentField(documentType: DocumentType) {
  return fieldPerDocumentType[documentType];
}

const documentDetailsFieldMap = {
  [DocumentType.Order]: 'orderDetails',
  [DocumentType.Quote]: 'quoteDetails',
  [DocumentType.Invoice]: 'invoiceDetails',
  [DocumentType.ReturnOrder]: 'returnOrderDetails',
  [DocumentType.CreditNote]: 'creditNoteDetails',
  [DocumentType.ReturnReceipt]: 'returnReceiptDetails',
  [DocumentType.Shipment]: 'shipmentDetails',
  // This was added just to conform with TypeScript due to addition of BlanketOrder to DocumentType.
  // I'm not sure if it's the correct values. I just set the same name as the routes found in:
  // SDK\Sana.Commerce.WebApp\ClientApp\src\webstore\routes\RouteName.ts
  [DocumentType.BlanketOrder]: 'salesAgreement',
  [DocumentType.CallOff]: 'orderDetails'
} as const;

export function getDocumentDetailsField(documentType: keyof typeof documentDetailsFieldMap) {
  return documentDetailsFieldMap[documentType];
}
