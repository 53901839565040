import styles from './MediaGallery.module.scss';
import { useRef, memo, useState, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import GalleryImage from './GalleryImage';
import GalleryControls from './GalleryControls';
import { Thumbnails } from './thumbnails';
import { usePrintMode } from 'utils/hooks';
import { useModal } from 'components/objects/modals';
import LargeGallery from './LargeGallery/Gallery';

const MediaGallery = ({ 
  items = [], 
  index, 
  noImageLarge, 
  showLargeImages=false,
  showFirstThumbnail=true,
  hideThumbnails=false,
}) => {
  const isPrintMode = usePrintMode();
  const [activeIndex, setActiveIndex] = useState(index < 0 ? 0 : index);
  const itemsList = filterItems(items, showLargeImages);
  const currentItem = itemsList[activeIndex];
  const itemsNumber = itemsList.length;
  const largeGallery = useModal();

  const showControls = useRef(currentItem && !!currentItem.large);
  if (currentItem && !!currentItem.large)
    showControls.current = true;

  const goTo = index => {
    if (index === activeIndex)
      return;
    if (index < 0 || index >= itemsNumber)
      return;
    setActiveIndex(index);
  };

  useEffect(() => {
    setActiveIndex(0);
  }, [items]);

  useLayoutEffect(() => {
    goTo(index);
  }, [index]);

  return (
    <>
      <div className={styles.mediaGallery}>
        <GalleryImage
          items={itemsList}
          index={activeIndex}
          onChange={goTo}
          noImage={noImageLarge}
          showLargeGallery={(!isPrintMode && currentItem && currentItem.large) ? largeGallery.show : null}
        />
        {!!itemsNumber && showControls.current && !isPrintMode && 
          <GalleryControls visibility={!!currentItem.large} openLargeGallery={largeGallery.show} />
        }
        {itemsNumber > 1 && !hideThumbnails &&
          <Thumbnails items={itemsList} index={activeIndex} onChange={goTo} showFirstThumbnail={showFirstThumbnail} />
        }
      </div>
      {largeGallery.opened && <LargeGallery hide={largeGallery.hide} items={itemsList} index={activeIndex} />}
    </>
  );
};

MediaGallery.propTypes = {
  items: PropTypes.array.isRequired,
  index: PropTypes.number,
  noImageLarge: PropTypes.string.isRequired,
  showLargeImages: PropTypes.bool,
  showFirstThumbnail: PropTypes.bool,
  hideThumbnails: PropTypes.bool,
};

export default memo(MediaGallery);

function filterItems(items, showLargeImages) {
  return items
    .map(item => {
      const { small, medium, large } = item;

      if (showLargeImages && large) {
        return {
          ...item,
          small: large,
        };
      }

      if (small)
        return item;

      return {
        ...item,
        small: medium || large,
      };
    })
    .filter(item => !!item.small);
}