import { initSystemPageContent } from 'behavior/pages/system';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { map } from 'rxjs/operators';
import { downlightDatasheetPageQuery } from './queries';

export default (routeData, _state$, { api }) => {
    const { params: { bomNo } } = routeData;

    return api.graphApi(downlightDatasheetPageQuery, { bomNo }).pipe(
      map(({ pages: { downlightDatasheet } }) => ({
        page: {
          ...downlightDatasheet,
          isPrintMode: true,
          hideCloseButton: true,
          isDatasheet: true,
          component: PageComponentNames.DownlightDatasheet,
        },
      })),
      initSystemPageContent(),
    );
};