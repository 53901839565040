exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".validationError_validationError{padding-top:.4em;padding-bottom:.4em;line-height:1.2em;font-size:12px;color:#cb2245}.validationError_validationIcon{margin-right:5px}", ""]);

// exports
exports.locals = {
	"validationError": "validationError_validationError",
	"validationIcon": "validationError_validationIcon"
};