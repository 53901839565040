import styles from './ProductConfigurator.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, useModal } from 'components/objects/modals';
import { finishProductConfigurator } from 'behavior/productConfigurator';

const ExplicitProductConfigurator = ({ productConfigurator, finishConfigurator, configuratorId }) => {
  if (!productConfigurator)
    return null;

  const className = !!configuratorId ? 
    `Modal_${configuratorId}` : 
    `Modal_${productConfigurator.configuratorExtensionId}`;

  const { configuratorUrl } = productConfigurator;
  const { opened, toggle } = useModal();
  const onClose = () => {
    toggle();
    finishConfigurator();
  };

  if (configuratorUrl && !opened)
    toggle();

  if (!configuratorUrl)
    return null;

  return (
    <Modal opened={opened} hide={onClose} className={className}>
      <iframe title="Product configurator" src={`${configuratorUrl}`} className={styles.iframe} />
    </Modal>
  );
};

ExplicitProductConfigurator.propTypes = {
  productConfigurator: PropTypes.object,
  finishConfigurator: PropTypes.func.isRequired,
  configuratorId: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    productConfigurator: state.page.productConfigurator,
  };
};

const mapDispatchToProps = {
  finishConfigurator: finishProductConfigurator,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExplicitProductConfigurator);