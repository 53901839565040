import { 
  CALLOFF_BEGIN,
	CALLOFF_BEGIN_RESPONSE,
  CALLOFF_HEADER_RESPONSE,
  CALLOFF_BODY_REQUEST,
  CALLOFF_BODY_RESPONSE,
  CALLOFF_SUBMIT_RESPONSE,
  CALLOFF_SUBMIT_COMPLETE,
  CALLOFF_SCHEDULE_BEGIN,
  CALLOFF_SUBMIT_SCHEDULE_RESPONSE,
  CALLOFF_QUANTITY_CHANGED,
  CALLOFF_SCHEDULE_SUBMIT_STEP_1,
  CALLOFF_BACK_TO_STEP_1,
  CALLOFF_SCHEDULE_SUBMIT_STEP_1_RESPONSE,
} from './actions';
import { unsetLoadingIndicator } from 'behavior/loadingIndicator';

const initialState = {
  header: {
    exists: false,
    type: -1,
  },
  step: 1,
  reference: '',
  shipmentDate: null,
  fetchingCallOffBody: false,
  finishedCallOffBody: false,
  submitted: false,
  finalised: false,
  callOffId: '',
  linesForCallOff: [],
};

export default function reducer(state = initialState, action) {
  if (action.type===CALLOFF_BEGIN) {
    return {
      ...state,
      ...initialState,
    };
  }

	if (action.type===CALLOFF_BEGIN_RESPONSE) {
		return {
			...state,
			projectContacts: action.payload.contacts.priceList.projectContact,
      shippingAddresses: action.payload.contacts.viewer.customer.shippingAddresses,
		};
	}

  if (action.type===CALLOFF_BODY_REQUEST) {
    return {
      ...state,
      authCode: action.payload.authCode,
      email: action.payload.email,
      fetchingCallOffBody: true
    };
  }

  if (action.type===CALLOFF_HEADER_RESPONSE) {

    return {
      ...state,
      header: action.payload.header,
    };

  }

  if (action.type===CALLOFF_BODY_RESPONSE || action.type===CALLOFF_SCHEDULE_SUBMIT_STEP_1_RESPONSE) {
    return {
      ...state,
      body: action.payload.callOff.body,
      fetchingCallOffBody: false,
      finishedCallOffBody: true,
    };
  }

  if (action.type===CALLOFF_SUBMIT_RESPONSE) {
    return {
      ...state,
      submitted: true,
      callOffId: action.payload.callOffId,
    };
  }

  if (action.type===CALLOFF_SUBMIT_COMPLETE) {
    unsetLoadingIndicator();
    return {
      ...state,
      submitted: false,
      finalised: true,
    };
  }

  if (action.type===CALLOFF_SCHEDULE_BEGIN) {
    return {
      ...state,
      scheduleSubmitted: false,
    };
  }

  if (action.type===CALLOFF_SUBMIT_SCHEDULE_RESPONSE) {
    return {
      ...state,
      scheduleSubmitted: true,
      step: 3,
    };
  }

  if (action.type===CALLOFF_SCHEDULE_SUBMIT_STEP_1) {
    return {
      ...state,
      step: 2,
      reference: action.payload.reference,
      shipmentDate: action.payload.shipmentDate,
      fetchingCallOffBody: true,
    };
  }

  if (action.type===CALLOFF_BACK_TO_STEP_1) {
    return {
      ...state,
      step: 1,
      reference: null,
      shipmentDate: null,
      linesForCallOff: [],
      finishedCallOffBody: false
    };
  }

  if (action.type===CALLOFF_QUANTITY_CHANGED) {
    const { payload } = action;
    const { linesForCallOff } = state;
    const hasLinesForCallOff = !!linesForCallOff;

    const linesToKeep = hasLinesForCallOff ? linesForCallOff.filter(line => line.id !== payload.id) : [];
    const callOffLines = [...linesToKeep, payload];

    return {
      ...state,
      linesForCallOff: callOffLines,
    };
  }

  return state;
}