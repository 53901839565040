import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { renderHTML } from 'utils/render';
import { ProductTitle, ProductGallery, ProductSpecifications } from './components';
import styles from './DownlightDatasheet.module.scss';

const DatasheetProduct = ({ product }) => {

  const { images, specifications, description } = product;

  const filterImagesBy = pattern =>
     images?.filter(image => image.medium?.match(pattern));

  const mainImage = filterImagesBy(/.*_10\.[a-zA-Z]+$/);
  const lineDrawing = filterImagesBy(/.*_20\.[a-zA-Z]+$/);
  const polarCurveImages = filterImagesBy(/.*_3[0-9]\.[a-zA-Z]+$/);
  const additionalImages = filterImagesBy(/.*_[4-9][0-9]\.[a-zA-Z]+$/);

  const getLargestImageUrl = img => 
    img.large ? img.large :
      img.medium ? img.medium :
        img.small;

	const showSpecifications = specifications && specifications.length>0;

  return (
    <div className="datasheet-product">
      <div className="product-title-screen">
        <ProductTitle product={product} />
      </div>
      <div className={`Details_description ${styles.datasheetDescription}`}>
          {renderHTML(description)}
      </div>
      <div className="image-section">
        <div className="main-image">
          <ProductGallery product={product} images={mainImage} />
        </div>
        {!!lineDrawing.length &&
          <div className="dimension-image">
            <div className="title">
              <SimpleText textKey="Dimension" />
            </div>
            <ProductGallery product={product} images={lineDrawing} />
          </div>}
      </div>

      {showSpecifications && 
        <div className={'product-sidebox specifications-box'}>
          <div className={'specifications-title'}>
            <SimpleText textKey="Product_TabSpecifications" />
          </div>
          <ProductSpecifications specifications={specifications} />
        </div>}
        {!!polarCurveImages.length && 
          <div className="image-section-polar">
            <div className="title">
              <SimpleText textKey="PolarCurve" />
            </div>
            <div className="polar-image">
              {polarCurveImages.map(x => (
                <div key={x.small} className="polar-image">
                  <img alt="" src={getLargestImageUrl(x)} />
                </div>
              ))}
            </div>
          </div>
        }
        {!!additionalImages.length &&
          <div className="image-section-additional">
            <div className="title">
              <SimpleText textKey="AdditionalInformation" />
            </div>
            {
              additionalImages.map(x => (
                <div key={x.small} className="additional-image">
                  <img alt="" src={getLargestImageUrl(x)} />
                </div>
              ))
            }
          </div>
        }
    </div>
  );
};

DatasheetProduct.propTypes = { 
  product: PropTypes.object.isRequired,
};

export default DatasheetProduct;