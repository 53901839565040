import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { finishProductConfigurator } from 'behavior/productConfigurator';
import ExplicitProductConfigurator from './ExplicitProductConfigurator';

const ProductConfigurator = ({ productConfigurator, finishConfigurator, configuratorId }) => {

  return (
    <ExplicitProductConfigurator
      productConfigurator={productConfigurator}
      finishConfigurator={finishConfigurator}
      configuratorId={configuratorId}
    />
  );
};

ProductConfigurator.propTypes = {
  productConfigurator: PropTypes.object,
  finishConfigurator: PropTypes.func.isRequired,
  configuratorId: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    productConfigurator: state.page.productConfigurator,
    configuratorId: state.page.product?.productConfiguratorInfo?.configuratorId,
  };
};

const mapDispatchToProps = {
  finishConfigurator: finishProductConfigurator,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductConfigurator);