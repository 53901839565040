import { ofType, combineEpics } from 'redux-observable';
import { switchMap, map } from 'rxjs/operators';
import { CHALLENGE_VERIFICATION_REQUEST, challengeSubmitResponse } from './actions';

import { RequestVerificationMutation } from './queries';

const submit$ = (action$, _state$, { api }) => {
  return action$.pipe(
    ofType(CHALLENGE_VERIFICATION_REQUEST),
    switchMap(({ payload }) =>
      api.graphApi(RequestVerificationMutation, { 
        input: {
          challengeCode: payload.challengeCode,
        },
      }).pipe(
        map(d => {
          return challengeSubmitResponse(d.verification.submitVerification);
        }),
      ),
    ),
  );
};

export default combineEpics(submit$);