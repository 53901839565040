import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { WithProductClickTracking } from 'components/objects/analytics';
import WithCatalogueNavigationRoute from '../../WithCatalogueNavigationRoute';
import styles from '../../PLP.module.scss';
import { Link } from 'components/primitives/links';

export const ThumbnailNoImage = ({ pageId, pageUrl, isVariant }) => {

  const { settings: { product: { noImage: { medium: noImageMedium } } } }  = 
    useSelector(s=>s);

  return ( 
    <WithProductClickTracking>
      {handleClick => (
        <WithCatalogueNavigationRoute productFamily={{ pageId, url: pageUrl, isVariant }}>
          {(productUrl, productRouteBuilder) => (
            <Link onClick={handleClick} url={productUrl} to={productRouteBuilder} className={styles.productTitle}>
              <img className="catalogue-navigation-no-image" src={noImageMedium} />
            </Link>
          )}
        </WithCatalogueNavigationRoute>
      )}
    </WithProductClickTracking>
  );
};

ThumbnailNoImage.propTypes = {
  pageId: PropTypes.string,
  pageUrl: PropTypes.string,
  isVariant: PropTypes.bool,  
};