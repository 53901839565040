import { useDispatch } from 'react-redux';
import styles from 'components/objects/forms/SanaForm.module.scss';
import { SimpleText } from 'components/sanaText';
import { SanaForm, FormGroup } from 'components/objects/forms';
import { Row } from 'components/primitives/grid';
import { TextBoxField } from 'components/objects/forms/fields';
import { useCallback, useEffect } from 'react';
import { requestChallengeVerification, challengeReset } from 'behavior/challenge';
import { useSelector } from 'react-redux';
import { routesBuilder } from 'routes';
import { navigateTo } from 'behavior/events';
import { ValidationError } from 'components/primitives/validationError';

const SendChallengeCode = () => {

  const dispatch = useDispatch();

  const { authenticated, reportFailure } = useSelector(x => x.challenge);

  const state = useSelector(x => x);

  useEffect(() => {
    if (!authenticated || authenticated ===null || authenticated === false ) {
      return;
    }
    dispatch(challengeReset());
    dispatch(navigateTo(routesBuilder.forHome()));
    window.location.reload();
  }, [authenticated]);

  const onSubmit = useCallback(formData => {

    dispatch(requestChallengeVerification(formData.code));

  }, []);

  if (!state.user.email) 
      return false;

  return (
    <>
      <Row mainAxisAlign="around">
        <h1 className={styles.formTitle}><SimpleText textKey="Challenge_Header" /></h1>
      </Row>
      <Row mainAxisAlign="around">
        <SimpleText textKey="Challenge_Description" />
      </Row>
      <Row mainAxisAlign="around">
       &nbsp;
      </Row>
      <Row mainAxisAlign="around">
        <b><SimpleText textKey="Challenge_VerificationText" /></b>
      </Row>
     
        <SanaForm 
          name="VerificationCode"
          onSubmit={onSubmit}
        >
          <Row mainAxisAlign="around" maxLength="2">
            <FormGroup
              fieldName="code"
              fieldComponent={TextBoxField}
              maxLength="10"
              className={styles.additional}
            />
         </Row>
         <Row mainAxisAlign="around">
          { reportFailure && <ValidationError textKey="Challenge_FailureText" /> }
         </Row>
        <SanaForm.SubmitRow />
        </SanaForm>
     
    </>
  );
};

export { SendChallengeCode };