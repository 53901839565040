import PropTypes from 'prop-types';
import { useProductFamilyRouteBuilder, useProductListRouteBuilder } from './hooks';

const WithCatalogueNavigationRoute = ({ children, productFamily: { pageId, url, isVariant }, withoutBackTo }) => {

  const routeBuilder = isVariant ? 
    useProductListRouteBuilder(pageId, withoutBackTo) : 
    useProductFamilyRouteBuilder(pageId, withoutBackTo);

  return children(url, routeBuilder);
};

WithCatalogueNavigationRoute.propTypes = {
  children: PropTypes.func.isRequired,
  productFamily: PropTypes.shape({
    pageId: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    isVariant: PropTypes.bool,
  }).isRequired,
  withoutBackTo: PropTypes.bool,
};

export default WithCatalogueNavigationRoute;

