import styles from './ConfirmationModal.module.scss';
import buttonStyles from 'components/primitives/buttons/Button.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components/objects/modals';
import { useSimpleTexts, SimpleText } from 'components/sanaText';

const ConfirmationModal = ({ 
    opened, 
    hide, 
    onConfirm,
    titleTextKey,
    messageTextKey,
  }) => {
  const [
    continueBtnText,
    closeBtnText,
  ] = useSimpleTexts([
    'ButtonText_Continue',
    'ButtonText_Close',
  ]).texts;

  return (
    <Modal opened={opened} hide={hide} className="sales-agreement-confirmation-modal">
      <div className={styles.modalContainer}>
        <h2><SimpleText textKey={titleTextKey} /></h2>
        <SimpleText textKey={messageTextKey} />
        <div className={styles.modalButtonsBlock}>
          <button className={linkStyles.link} onClick={hide}>{closeBtnText}</button>
          <button
            onClick={onConfirm}
            className={`${buttonStyles.btn} ${buttonStyles.btnAction} ${buttonStyles.btnSmall} ${buttonStyles.btnConfirm} ${styles.continue}`}
            aria-disabled={!onConfirm}
          >
            {continueBtnText}
          </button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  titleTextKey: PropTypes.string.isRequired,
  messageTextKey: PropTypes.string.isRequired,
};

export default memo(ConfirmationModal);