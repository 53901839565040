import PropTypes from 'prop-types';
import { ThumbnailNoImage } from './ThumbnailNoImage';
import { ThumbnailSlider } from './ThumbnailSlider';

const Thumbnails = ({ images, pageId, pageUrl, isVariant }) => {
  const hasThumbnails = images.length > 0;

  const theImages = hasThumbnails ?
    isVariant ? [images[0]] : images
    : [];

  return hasThumbnails ?
    <ThumbnailSlider images={theImages} pageId={pageId} pageUrl={pageUrl} isVariant={isVariant} /> :
    <ThumbnailNoImage pageId={pageId} pageUrl={pageUrl} isVariant={isVariant} />;
};

Thumbnails.propTypes = {
  images: PropTypes.array,
  pageId: PropTypes.string,
  pageUrl: PropTypes.string,
  isVariant: PropTypes.bool,
};

export default Thumbnails;