import styles from './MainNav.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/primitives/links';
import { SimpleText } from 'components/sanaText';
import { shouldRenderNavigation } from '../helpers';
import { MainNavItemPropTypes } from './PropTypes';
import { setFocus } from './eventHandlers';
import { useResponsiveBreakpoints } from 'utils/layout';
import { useNavMenuContext } from './hooks';


const MultiColumnView = ({
  item: {
    title,
    cssClass,
    link,
    children: subItems,
    image,
  },
  itemCount,
  viewMoreVisible,
  ariaSubmenuText,
}) => {

const { showViewMoreLink } = useNavMenuContext();
const { lg, xl, md } = useResponsiveBreakpoints();
const showImagesForResponsive = (md || lg || xl) ? true : false;
const showImages = (showImagesForResponsive && itemCount > 0) ? true : false;

return (

    <li className={`${styles.navItemBlock} ${cssClass || ''}`}>
      <Link {...link} className={styles.hypSecondLvl} onClick={setFocus} onContextMenu={setFocus}>
        {image?.path && showImages && <><div className={styles.navItemImage}><img src={image?.path} alt={title} title={title} className={styles.navItemImageResponsive} /></div><br /></> }
        {!image?.path && showImages && <><div className={(xl ? styles.navItemNoImage : styles.navItemNoImageMd)} /><br /></>}
        {title}
      </Link>
      {shouldRenderNavigation(subItems) && (
        <ul className={styles.navList} aria-label={`${title} ${ariaSubmenuText}`}>
          {subItems.map(({ id, title, cssClass, link }) => {
            return (
              <li key={id} className={cssClass || null}>
                <Link {...link} className={styles.hypDefault} onClick={setFocus} onContextMenu={setFocus}>
                  {title}
                </Link>
              </li>
            );
          })}
          {showViewMoreLink && link != null && !!link.url && link.url !== '#' && (
            <li>
              <Link {...link} className={styles.hyp} onClick={setFocus}>
                <SimpleText textKey="ViewMore" /> {title}
              </Link>
            </li>
          )}
        </ul>
      )}
    </li>
  );
};

MultiColumnView.propTypes = {
  item: MainNavItemPropTypes,
  itemCount: PropTypes.number,
  viewMoreVisible: PropTypes.bool,
  ariaSubmenuText: PropTypes.string,
};

export default memo(MultiColumnView);
