import styles from '../AccountMenu.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { WishlistLink, MyAccountLink } from 'components/primitives/links';
import { useSelector } from 'react-redux';
import LogoutButton from './LogoutButton';

const CustomerMenuItems = ({ logoutDelay }) => {
  const name = useSelector(state => state.user.name);
	const selectedProjectName = useSelector(state => state.basket.summary.selectedProjectName);

  return (
    <>
      <div className={styles.bodyHeader}>
        <span className="user-name"><SimpleText textKey="Login_Welcome" /> <b>{name}</b></span>
      </div>
      {selectedProjectName &&
      <span className={styles.item}><SimpleText textKey="SelectedProject"/>: <span id="header-project-name">{selectedProjectName}</span></span>}
      <MyAccountLink className={styles.item} />
      <WishlistLink className={styles.item} />
      <LogoutButton logoutDelay={logoutDelay} />
    </>
  );
};

CustomerMenuItems.propTypes = {
  logoutDelay: PropTypes.number,
};

export default CustomerMenuItems;
