import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { backToStep1 } from 'behavior/calloff';
import { Col, Row } from 'components/primitives/grid';
import { SanaForm, FormGroup } from 'components/objects/forms';
import { TextBoxField } from 'components/objects/forms';
import { SanaButton } from 'components/primitives/buttons';
import styles from 'components/objects/profile/salesAgreements/SalesAgreements.module.scss';
import { getToLocaleDate } from 'utils/format';
import { useCultureName } from 'utils/hooks';
import { useGetHeaderTexts } from '../hooks';

const Step2Header = ({ reference, shipmentDate, orderNo, projectCode, projectName }) => {
  
  const culture = useCultureName();
  const formatDate = getToLocaleDate(culture);

  const dispatch = useDispatch();

  const {
    referenceTitle,
    shipmentDateTitle,
    orderNoTitle,
    projectNameTitle,
  } = useGetHeaderTexts();

  const headerDetails = {
    reference,
    shipmentDate: formatDate(shipmentDate),
    orderNo,
    projectCode,
  };

  const handleBackClick = () => {
    dispatch(backToStep1());
  };
  
  return (
    <Row className={`${styles.details} calloff-schedule-step-2-header`}>
      <Col xs={12} sm={8} xl={7} className="step2-header-columns">
        <h3>{projectNameTitle}: {projectCode} - {projectName}</h3>
        <h3>{orderNoTitle}: {orderNo}</h3>
        <h3>{referenceTitle}: {reference}</h3>
        <h3>{shipmentDateTitle}: {headerDetails.shipmentDate}</h3>
        <SanaButton textKey="Back" onClick={handleBackClick} className="back-button" />
      </Col>
    </Row>
  );
};

Step2Header.propTypes = {
  reference: PropTypes.string.isRequired,
  shipmentDate: PropTypes.string.isRequired,
  orderNo: PropTypes.string.isRequired,
  projectCode: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
};

export default connect(
  ({ 
    callOff: { 
      reference, 
      shipmentDate,
      body: {
        id: orderNo,
        projectCode,
        projectName,
      },
    },
  }) => ({ reference, shipmentDate, orderNo, projectCode, projectName }),
)(Step2Header);