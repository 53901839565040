import styles from '../MainNav.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import OpenerButton from '../OpenerButton';
import { useSanaTexts } from 'components/sanaText';
import { stripHtmlTagsAndFormatting } from 'utils/helpers';
import { useIsMainNavItemsLoaded } from '../hooks';

const textsToLoad = ['Aria_MainNavigation_ModalToggleButton'];

const ModalNavOpener = ({ visible, onClick }) => {
  const [ariaModalNavToggleButtonText] = useSanaTexts(textsToLoad, stripHtmlTagsAndFormatting).texts;
  const disabled = !useIsMainNavItemsLoaded();

  return (
    <OpenerButton
      onClick={onClick}
      className={styles.btnModalNavOpener}
      aria-disabled={disabled || null}
      tabIndex={disabled ? '-1' : null}
      aria-label={ariaModalNavToggleButtonText}
      aria-pressed={visible}
      aria-controls="mainNavigation"
    />
  );
};

ModalNavOpener.propTypes = {
  visible: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default memo(ModalNavOpener);
