import { initSystemPageContent, loadSystemPageQuery } from 'behavior/pages/system';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { map } from 'rxjs/operators';

export default (routeData, _state$, { api }) => {
    return api.graphApi(loadSystemPageQuery('challenge')).pipe(
      map(({ pages: { challenge } }) => ({
        page: {
          ...challenge,
          component: PageComponentNames.Challenge,
        },
      })),
      initSystemPageContent(),
    );
};