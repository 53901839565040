import PageB2C from '../pageContent/Page';
import PageTemplate from './PageTemplate';
import { PageComponentNames } from 'behavior/pages';

function catalogueNavigationListPageRenderer(page) {

  const Page = PageB2C;

  if (!Page.selectProps)
    throw new Error(`Product list page component ${Page.displayName} should implement selectProps method to map page state to props.`);

  return (
    <PageTemplate {...PageTemplate.selectProps(page)}>
      <Page {...Page.selectProps(page)} />
    </PageTemplate>
  );
}

export default {
  [PageComponentNames.CatalogueNavigation]: catalogueNavigationListPageRenderer,
};