import { useSelector, useDispatch } from 'react-redux';
import { backToStep1 } from 'behavior/calloff';
import { Button } from 'components/primitives/buttons';
import { useSimpleTexts  } from 'components/sanaText';
import { renderHTML } from 'utils/render';

const Step3 = () => {
  
  const { orderId } = useSelector(s => s.page);

  const dispatch = useDispatch();

  const [
    confirmationTitle,
    confirmationText,
    linkText
  ] = useSimpleTexts([
    'ScheduleCallOffOrder_ConfirmationTitle',
    'ScheduleCallOffOrder_ConfirmationText',
    'ScheduleCallOffOrder_CallOffAgainLinkText',
  ]).texts;

  if (!(!!confirmationText)) {
    return null;
  }

  return (
    <>
      {
        (confirmationTitle && confirmationTitle.trim()) &&
        <h1>{confirmationTitle}</h1>
      }
      
      <p>
          {renderHTML(confirmationText)}&nbsp;
          <Button className="calloff-back-to-step-1" onClick={() => dispatch(backToStep1())}>
            {linkText}
          </Button>
      </p>
    </>
  );
};

export default Step3;