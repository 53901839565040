import DesktopAccountMenuBase from './DesktopAccountMenuBase';
import { TogglePresentationOption } from './constants';
import { ThemeFontSize } from 'behavior/theme';

const defaultOptions = {
  toggle_Presentation: TogglePresentationOption.IconOnly,
  toggle_ThemeFontSize: ThemeFontSize.Regular,
  toggle_AllCaps: false,
  content_ShowExpandIcon: true,
  content_ThemeFontSize: ThemeFontSize.Regular,
};

const DesktopAccountMenu = () => <DesktopAccountMenuBase options={defaultOptions} elementId="desktopAccountMenu" />;

export default DesktopAccountMenu;
