import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { routesBuilder } from 'routes';
import { toUrlHash } from 'utils/url';
import { Steps } from 'behavior/pages/checkout';

export function useIncompleteStepsInfo(steps) {
  return useMemo(
    () => {
      const incompleteSteps = new Set();

      for (const step of steps)
        !step.isCompleted && incompleteSteps.add(step.id);

      return incompleteSteps;
    }, [steps]);
}

const stepNames = Object.values(Steps);

export function useGetStepNavigationInfo(isPromotion, isQuote, isGuest) {
  const { pathname, search, routeData } = useSelector(({ routing: { location, routeData } }) => ({
    pathname: location.pathname,
    search: location.search,
    routeData,
  }), shallowEqual);

  return step => {
    const hash = step !== stepNames[0] ? toUrlHash(step) : '';
    let to, url = hash;

    if (routeData.params?.previewToken) {
      to = { ...routeData, options: { step } };
      url = pathname + search + hash;
    } else if (isPromotion) {
      to = routesBuilder.forQuotePromotion(step);
    } else {
      to = routesBuilder.forCheckout(isQuote, step, isGuest);
    }

    return { to, url };
  };
}

export function useStepNavigationInfo(step, isPromotion, isQuote, isGuest) {
  const getStepNavigationInfo = useGetStepNavigationInfo(isPromotion, isQuote, isGuest);

  return getStepNavigationInfo(step);
}

export function useIsOverCreditLimit() {
  const  { customer } = useSelector(s => s.user);
  const { price: basketValue } = useSelector(s => s.page.info.totals);

  if (!customer) {
    return;
  }

  const { creditLimit, balance, paymentTermsCode } = customer;

  const totalBalance = balance + basketValue;

  if (paymentTermsCode==='0DAYS') {
    return false;
  }

  return creditLimit < totalBalance;
}
