export const DOWNLIGHT_DATASHEET_PRODUCTS_REQUEST = 'DOWNLIGHT_DATASHEET/PRODUCTS_REQUEST';

export const requestDownlightDatasheetProducts = productIds => {
  const result = {
    type: DOWNLIGHT_DATASHEET_PRODUCTS_REQUEST,
    payload: { productIds }, 
  };

  return result;
};

export const DOWNLIGHT_DATASHEET_PRODUCTS_BY_BOM_REQUEST = 'DOWNLIGHT_DATASHEET/PRODUCTS_BY_BOM_REQUEST';
export const requestDownlightDatasheetProductsByBom = payload => {
  const result = {
    type: DOWNLIGHT_DATASHEET_PRODUCTS_BY_BOM_REQUEST,
    payload,
  };

  return result;
}

export const DOWNLIGHT_DATASHEET_PRODUCTS_BY_BOM_RECEIVED = 'DOWNLIGHT_DATASHEET/PRODUCTS_BY_BOM_RECEIVED';
export const receiveDownlightDatasheetProductsByBom = data => {
  const result = {
    type: DOWNLIGHT_DATASHEET_PRODUCTS_BY_BOM_RECEIVED,
    payload: data,
  };

  return result;
}

export const DOWNLIGHT_DATASHEET_PRODUCTS_RECEIVED = 'DOWNLIGHT_DATASHEET/PRODUCTS_RECEIVED';

export const receiveDownlightDatasheetProducts = data => {
  const result = {
    type: DOWNLIGHT_DATASHEET_PRODUCTS_RECEIVED,
    payload: { data }, 
  };

  return result;
};
