import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import Step2Header from './Step2Header';
import Step2Lines from './Step2Lines';
import Step2Submit from './Step2Submit';
import Step2Summary from './Step2Summary';
import { ProductConfigurator } from 'components/objects/productConfigurator';
const Step2 = ({ callOffEmail }) => {
  return (
    <div className="calloff-schedule-step-2">
      <Step2Header />
      <div className="calloff-schedule-step2-lines-section">
        <h2><SimpleText textKey="ScheduleCallOffOrder_LinesHeader" /></h2>
        <div className="call-off-section-wrapper">
          <Step2Lines />
        </div>
      </div>
      <div className="calloff-schedule-step2-summary-section">
        <div className="call-off-section-wrapper">
          <Step2Summary />
        </div>
      </div>
      <Step2Submit callOffEmail={callOffEmail} />
      <ProductConfigurator/>
    </div>
  );
};

Step2.propTypes = {
  callOffEmail: PropTypes.string.isRequired,
};

export default Step2;