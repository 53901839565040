import { CHALLENGE_SUBMIT_RESPONSE, CHALLENGE_RESET } from './actions';

const initialState = {
  authenticated: false,
  reportFailure: false,
};

export default function reducer(state = initialState, action) {
  
  if (action.type===CHALLENGE_SUBMIT_RESPONSE) {

    let isAuthenticated = false;
    let hasFailed = false;

    if (action.payload.challengeAuthenticated === 'True') {
       isAuthenticated = true; 
      }
     else {
       hasFailed = true; 
    }
     
    return {
      ...state,
      authenticated: isAuthenticated,
      reportFailure: hasFailed,
    };

  }

  if (action.type===CHALLENGE_RESET) {

    return {
      ...state,
      authenticated: false,
      hasFailed: true,
    };

  }

  return state;
}