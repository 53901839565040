import DesktopLanguageSelectorBase from './DesktopLanguageSelectorBase';
import { TogglePresentationOption } from './constants';
import { ThemeFontSize } from 'behavior/theme';

const defaultOptions = {
  toggle_Presentation: TogglePresentationOption.FlagOnly,
  toggle_FontSize: ThemeFontSize.Regular,
  toggle_AllCaps: false,
  content_ShowFlags: true,
  content_AllCaps: false,
  content_ShowExpandIcon: true,
  content_ItemFontSize: ThemeFontSize.Regular,
};

const DesktopLanguageSelector = () => (
  <DesktopLanguageSelectorBase options={defaultOptions} elementId="desktopLanguageSelector" />
);

export default DesktopLanguageSelector;
