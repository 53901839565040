import styles from '../../Checkout.module.scss';
import PropTypes from 'prop-types';
import { SanaForm, EntityTemplateFields } from 'components/objects/forms';
import { HiddenSubmitButton } from 'components/primitives/form';
import { useComputedValue } from 'utils/hooks';
import { zipObjectDeep } from 'lodash';

const AddressForm = ({
  templateFields,
  values,
  onSubmit,
  submitOnBlur,
  formikRef,
  onFormValidate,
  validateOnBlur,
}) => {
  const onFormBlur = submitOnBlur || validateOnBlur
    ? (e, formik) => {
      if (e.currentTarget.contains(e.relatedTarget || document.activeElement))
        return;

      formik.validateForm().then(errors => {
        const invalid = Object.keys(errors).length;

        onFormValidate && onFormValidate(!invalid);

        if (invalid)
          return;

        submitOnBlur && onSubmit(formik.values, formik);
      });
    }
    : null;

  const initialValues = useComputedValue(() => {
    if (values)
      return zipObjectDeep(Object.keys(values), Object.values(values));

    return undefined;
  }, values);

  return (
    <SanaForm
      name="Address"
      className={styles.custom}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onBlur={onFormBlur}
      shouldReset={templateFields}
      formikRef={formikRef}
    >
      <EntityTemplateFields applyNamesEscaping fields={templateFields} omitInitials={!!values} />
      <HiddenSubmitButton />
    </SanaForm>
  );
};

AddressForm.propTypes = {
  templateFields: PropTypes.array.isRequired,
  values: PropTypes.any,
  onSubmit: PropTypes.func.isRequired,
  submitOnBlur: PropTypes.bool,
  formikRef: PropTypes.any,
  onFormValidate: PropTypes.func,
  validateOnBlur: PropTypes.bool,
};

export default AddressForm;
