import PropTypes from 'prop-types';
import { useState, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { calloffQuantityChanged } from 'behavior/calloff';
import { Input } from 'components/primitives/form';
import { SimpleText } from 'components/sanaText';
import { useIsProductionItem } from 'utils/hooks/orlight';
import styles from '../../scheduleCallOffOrder.module.scss';
import Step2Stock from './Step2Stock';
import { useCanCallOffAnyAmount } from '../hooks';
import { ViewButton } from 'components/objects/productConfigurator';
const Step2Line = ({ 
  line, 
  noImage, 
  linesForCallOff,
  shipmentDate,
  effectiveDate,
 }) => {

  if (line && line.product && !(line.product.stockLevels)) {
    line.product.stockLevels = {
      outOfStock: null,
      lowStock: null
    }
  }
  const { 
    qtyOnPurchaseOrder,
    product: {
      routingNo,
      inventory,
      id: productId,
      stockLevels: {
        outOfStock: outOfStockLevel,
      },
    },
  } = line;

  console.debug({line});

  const isProductionItem = useIsProductionItem({ routingNo, id: productId });

  const dispatch = useDispatch();

  const canCallOffAnyAmount = useCanCallOffAnyAmount(effectiveDate, shipmentDate);

  const getInitialQuantity = () => {
    if (!(!!linesForCallOff)) {
      return 0;
    }

    const [lineForCallOff] = linesForCallOff.filter(l => l.id===line.id);

    if (!!lineForCallOff) {
      return lineForCallOff.quantity;
    }

    return 0;
  };

  const isStockCheckOverridden = isProductionItem || canCallOffAnyAmount;

  const canCallOff = () => {
    // This function can be reduced to just returning true, but in case some conditions need to change,
    // I'm letting the conditions be here.
    if (isStockCheckOverridden) {
      return true;
    }

    if (inventory <= outOfStockLevel) {
      return true;
    }
    
    return true;
  };

  const isQtyOverInventory = q => {

    const isOver = q > (inventory + qtyOnPurchaseOrder);

    if (isStockCheckOverridden) {
      return false;
    }

    return isOver;
  };

  const initialQuantity = getInitialQuantity();
  const [quantity, setQuantity] = useState(initialQuantity);
  const [qtyOverRemaining, setQtyOverRemaining] = useState(initialQuantity > line.quantities.remaining);
  const [qtyIsOverInventory, setQtyIsOverInventory] = useState(isQtyOverInventory(initialQuantity));

  const { small: noImageSmall } = noImage;

  const getQtyOrdered = quantities => {
    const { remaining, value, totalQuantityOrdered} = quantities;
    //return value - remaining;
    return totalQuantityOrdered;
  };

  const getImgSrc = product => {
    const hasImage = !!product.image && !!product.image.small;
    return hasImage ? product.image.small : noImageSmall;
  };

  const handleQuantityChange = (e, line) => {
    const {
      target: {
        value: quantity,
      },  
    } = e;  

    const { id } = line;

    setQuantity(quantity);
    setQtyOverRemaining(quantity > line.quantities.remaining);
    setQtyIsOverInventory(isQtyOverInventory(quantity));
    dispatch(calloffQuantityChanged({ id, quantity }));
  };

  let configuredItemDescription = null;

  let productConfigurationViewProps = useMemo(() => {

    if (!line) {
      return undefined;
    }
    
    return undefined;
  }, [line]);

  let displayConfigurationData = (line.product.id === 'KIT DOWNLIGHT' || line.configuratorBomNo);

  // Please refer to OSP-455 to check the feedback related to configuration presentation
  if (displayConfigurationData) {
    configuredItemDescription = (
      <div className={styles.tableConfigData}>
        <div className={styles.description}>{line.description}</div>
        <div className={styles.configBomNo}><SimpleText textKey="General_ConfigBomNo" />: <span>{line.configuratorBomNo}</span></div>

        {/* In case you want to display the Configurator ID */}
        {/* <div className={styles.configuratorIdErp}><SimpleText textKey="General_ConfiguratorId" />:<br/><span>{line.configuratorId}</span></div> */}
        
        <div className={styles.configLineReference}><SimpleText textKey={'OrderDetails_LineReference'}></SimpleText>:<br/> <span>{line.lineReference}</span></div>
        {
          (line.product.id === 'KIT DOWNLIGHT') &&
          <div className={styles.lineActionsIdCell}>
            <ViewButton productConfiguration={line.sanaProductConfigurationInfo}></ViewButton>
          </div>
        }
      </div>
    )
  }

  let extraInfoContent = (
    <div className={styles.tableExtraInfo}>
      {
        (!displayConfigurationData) &&
        (line.lineReference) &&
        <div className={styles.lineReference}><SimpleText textKey={'OrderDetails_LineReference'}></SimpleText>:<br/> {line.lineReference}</div>
      }
    </div>
  )
  

  return(
    <>
      <tr className="calloff-schedule-step-2-line">
        <td className="image-cell">
          <img 
            src={getImgSrc(line.product)} 
            className={'PLP_product-img'}  
            alt={line.product.title}
          />
        </td>
        <td className="product-id-cell">
          <div>
            <div>{line.product.id}</div>
            {configuredItemDescription}
            {extraInfoContent}
          </div>
          
        </td>
        <td className="product-title-cell">{line.product.title}</td>
        <td className="quantity-ordered-cell">{getQtyOrdered(line.quantities)}</td>
        <td className="quantity-remaining-cell">{line.quantities.remaining}</td>
        <td className="availability-cell">
          <Step2Stock product={line.product} />
        </td>
        <td className="quantity-cell">
          <Input 
            type="number" 
            min="0" 
            value={quantity} 
            onChange={e => handleQuantityChange(e, line)} 
            disabled={!canCallOff()}
          />
        </td>
      </tr>
      {qtyOverRemaining && 
        <tr className={`calloff-schedule-step2-line-error row-erro ${styles.errorText}`}>
          <td colSpan="7">
            <p><SimpleText textKey="ScheduleCallOffOrder_QuantityExceedsRemaining" /></p>
          </td>
        </tr>
      }
      {/* 
      {qtyIsOverInventory && 
        <tr className={`calloff-schedule-step2-line-error row-erro ${styles.errorText}`}>
          <td colSpan="7">
            <p><SimpleText textKey="ScheduleCallOffOrder_QuantityExceedsInventory" /></p>
          </td>
        </tr>
      }
      */}
    </>
  );
};

Step2Line.propTypes = { 
  line: PropTypes.object.isRequired,
  noImage: PropTypes.object,
  linesForCallOff: PropTypes.array,
  shipmentDate: PropTypes.string,
  effectiveDate: PropTypes.string,
};

export default connect(
  ({
    settings: {
      product: {
        noImage,
      },
    },
    callOff: {
      linesForCallOff,
      body: {
        effectiveDate,
      },
    },
    page: {
      shipmentDate,
    },
  }) => ({ noImage, linesForCallOff, shipmentDate, effectiveDate }),
)(Step2Line);