import { useSimpleTexts } from 'components/sanaText';

export function useGetHeaderTexts() {
  const [
    orderNoTitle,
    projectCodeTitle,
    referenceTitle,
    shipmentDateTitle,
    projectNameTitle,
  ] = useSimpleTexts([
    'OrderNo',
    'ProjectCode',
    'CallOffReference',
    'ShipmentDate',
    'ProjectName',
  ]).texts;

  return { orderNoTitle, projectCodeTitle, referenceTitle, shipmentDateTitle, projectNameTitle };
}

export function useCanCallOffAnyAmount(effectiveDate, shipmentDate) {

  // Get number of months between effectiveDate and shipmentDate
  const monthsSinceEffectiveDate = Math.abs(
    (new Date(shipmentDate) - new Date(effectiveDate)) / (1000 * 60 * 60 * 24 * 30));

  return monthsSinceEffectiveDate > 3;
}
