import { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeRichText } from 'utils/render';
import styles from '../DownlightDatasheet.module.scss';
import useEditableSpecifications from './useEditableSpecifications';

const ProductSpecifications = ({ specifications }) => {
  if (!specifications || !specifications.length)
    return null;
  
  return (
    <dl className={`${styles.tableList} Details_table-list`}>
      {useEditableSpecifications(specifications).map(({ name, value }, index) => (
        <Fragment key={index}>
          <dt className={`${styles.name} Details_name`}>{name}</dt>
          <dd className={`${styles.value} Details_value`}>{makeRichText(value)}</dd>
        </Fragment>
      ))}
    </dl>
  );
};

ProductSpecifications.propTypes = {
  specifications: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  })),
};

export default memo(ProductSpecifications);
