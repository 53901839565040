import epic from './epics';
import reducer from './reducer';

export { submitCallOffMutation } from './queries';
export {
  CALLOFF_SUBMIT_REQUEST,
  CALLOFF_SUBMIT_RESPONSE,
  requestCallOff,
  requestCallOffHeader,
  requestCallOffBody,
  callOffSubmitComplete,
  beginCallOff,
  requestSendCallOffEmail,
  submitCallOffSchedule,
  beginCallOffSchedule,
  submitCallOffScheduleStep1,
  calloffQuantityChanged,
  backToStep1,
} from './actions';

export const CALLOFF_TYPE_EMAILS = 0;
export const CALLOFF_TYPE_LINK = 1;

export { epic, reducer };