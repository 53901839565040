import { ofType, combineEpics } from 'redux-observable';
import { switchMap, map } from 'rxjs/operators';
import { ProductSpecificationFilter } from 'behavior/products/product';
import { 
  DOWNLIGHT_DATASHEET_PRODUCTS_REQUEST,
  DOWNLIGHT_DATASHEET_PRODUCTS_BY_BOM_REQUEST,
  receiveDownlightDatasheetProducts,
  receiveDownlightDatasheetProductsByBom
} from  './actions';
import { 
  getConfiguredProductsQuery,
  getConfiguredProductIdsForBomQuery
} from './queries';

const getProducts$ = (action$, _state$, { api }) => {
	return action$.pipe(
		ofType(DOWNLIGHT_DATASHEET_PRODUCTS_REQUEST),
		switchMap(({ payload }) => 
			api.graphApi(getConfiguredProductsQuery(getOptions(payload)), 
        {
          options: { ids: getOptions(payload).ids },
          specificationFilter: ProductSpecificationFilter.ForDetails,
          detailsPreset: 'Details',
          loadRelatedProductsCategories: false,
        },
      ).pipe(map(d => receiveDownlightDatasheetProducts(d)))),
	);
};

const getProductsByBom$ = (action$, _state$, { api }) => {
  return action$.pipe(
    ofType(DOWNLIGHT_DATASHEET_PRODUCTS_BY_BOM_REQUEST),
    switchMap(({ payload }) =>
      api.graphApi(getConfiguredProductIdsForBomQuery(payload), 
        { 
          input: {
            bomNo: payload.bomNo,
            ledNo: payload.ledNo,
          },
        }).pipe(map(d => receiveDownlightDatasheetProductsByBom(d)))),
    );
};

const getOptions = payload => {
  return {
    ids: payload.productIds, 
    isInsiteEditor: false,
    relatedProducts: {
      includeLargeImages: false,
    },
  };
};

export default combineEpics(getProducts$, getProductsByBom$);