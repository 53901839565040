import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Tooltip from 'react-tooltip-lite';
import { SimpleText } from 'components/sanaText';
import './HelpIcon.css';
import TextHelpIcon from './TextHelpIcon';
import ImageHelpIcon from './ImageHelpIcon';

const HelpIcon = ({ 
  helpTextKey, 
  direction = 'up', 
  className = '',
}) => {
  const { helpIconUrl } = useSelector(s => s.settings);

  return (
    <>
      <Tooltip
        content={(
          <SimpleText textKey={helpTextKey} />
        )}
        className={`target ${className}`}
        eventToggle="onClick"
        tagName="span"
        direction={direction}
      >
        {helpIconUrl==='' ? 
          <TextHelpIcon /> : 
          <ImageHelpIcon helpIconUrl={helpIconUrl} />}
      </Tooltip>
    </>
  );
};

HelpIcon.propTypes = {
  helpTextKey: PropTypes.string.isRequired,
  direction: PropTypes.string,
};

export default HelpIcon;