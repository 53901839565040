import { memo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {
  useProductTrackingOptions,
  createProductListStructure,
} from 'utils/structureData';
import renderOnce from 'components/objects/renderOnce';

const Schema = ({ productFamilies }) => {
  const options = useProductTrackingOptions();

  if (!productFamilies || !productFamilies.length)
    return null;

  const schema = createProductListStructure(productFamilies, options);

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
};

Schema.propTypes = {
  productFamilies: PropTypes.object,
};

export default renderOnce(memo(Schema, _ => true));