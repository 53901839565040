import linkStyles from 'components/primitives/links/Link.module.scss';
import configuratorStyles from '../ProductConfigurator.module.scss';

import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { requestViewProductConfiguration } from 'behavior/productConfigurator';
import { SimpleText } from 'components/sanaText';
import { generateKey } from 'utils/helpers';

const ViewButton = ({ 
  productConfiguration,
  viewProductConfiguration,
  productConfigurator,
  isConfiguratorAvailable,
}) => {

  const [componentId] = useState(generateKey);
  const [configurationFailed, setConfigurationFailed] = useState(false);

  useEffect(() => {
    if (productConfigurator
      && !productConfigurator.configuratorUrl
      && productConfigurator.updatedById === componentId) {
      setConfigurationFailed(true);
    }
  }, [productConfigurator]);

  const handleViewConfiguration = () => {
    viewProductConfiguration(productConfiguration.id);
  };

  const isConfiguratorDisabled = !isConfiguratorAvailable;
  const isButtonDisabled = isConfiguratorDisabled || configurationFailed;

  return (
    <button
      type="button"
      className={`${linkStyles.link} ${configuratorStyles.reconfigureButton}`}
      onClick={handleViewConfiguration}
      aria-disabled={isButtonDisabled}
      tabIndex={isButtonDisabled ? -1 : null}
    >
      <SimpleText textKey="View" />
    </button>
  );
};

ViewButton.propTypes = {
  productConfiguration: PropTypes.object,
  productConfigurator: PropTypes.object,
  isConfiguratorAvailable: PropTypes.bool,
  viewProductConfiguration: PropTypes.func,
};

const mapStateToProps = state => ({
  productConfigurator: state.page.productConfigurator,
  isConfiguratorAvailable: state.settings.product.isConfiguratorAvailable,
});

export default connect(mapStateToProps, { viewProductConfiguration: requestViewProductConfiguration })(ViewButton);