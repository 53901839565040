import styles from './Thumbnails.module.scss';
import { memo, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { styleTranslate, getContentWidth, getOuterWidth } from '../helpers';
import Swipe from 'react-easy-swipe';
import { resize$, useEventObservable } from 'utils/rxjs';
import { usePrintMode } from 'utils/hooks';
import Thumbnail from './Thumbnail';
import { BigArrowLeftIcon, BigArrowLeftPassiveIcon, BigArrowRightIcon, BigArrowRightPassiveIcon } from 'components/primitives/icons';

const useThumbnailsState = index => {
  const stateRef = useRef();
  if (!stateRef.current)
    stateRef.current = {
      width: null,
      itemWidth: 0,
      itemsInView: 1,
      active: index,
      listElement: null,
      btnPrevElement: null,
      btnNextElement: null,
    };

  return stateRef.current;
};

const Thumbnails = ({ items, index = 0, onChange, showFirstThumbnail = true }) => {
  if (!items.length)
    return null;

  const isPrintMode = usePrintMode();
  const containerRef = useRef(null);
  const state = useThumbnailsState(index);

  const animateNext = () => {
    const activeElement = state.listElement.getElementsByClassName('active')[0];
    activeElement && activeElement.classList.remove('active');
    state.listElement.getElementsByClassName(styles.thumbnail)[state.active].classList.add('active');

    if (isPrintMode)
      return;

    state.btnPrevElement.disabled = state.active <= 0;
    state.btnNextElement.disabled = state.active >= items.length - 1;

    const listShift = getShiftSet(state.active, items.length, state.itemsInView);
    Object.assign(state.listElement.style, styleTranslate(- state.itemWidth * listShift, 0, 'px'));
  };

  const resetLayout = () => {
    state.width = getContentWidth(containerRef.current);
    state.itemWidth = getOuterWidth(state.listElement.firstElementChild);
    state.itemsInView = Math.floor(state.width / state.itemWidth);
    if (state.itemsInView > items.length)
      state.itemsInView = items.length;

    const controlsElement = containerRef.current.getElementsByClassName(styles.navControls)[0];
    controlsElement.style.visibility = state.itemsInView < items.length ? 'visible' : 'hidden';

    const cntElement = containerRef.current.getElementsByClassName(styles.cnt)[0];
    cntElement.style.width = state.itemWidth * state.itemsInView + 'px';

    animateNext();
  };

  const goTo = useCallback(index => {
    if (index === state.active)
      return;
    if (!items[index])
      return;

    state.active = index;
    animateNext();

    onChange && onChange(state.active);
  }, [index, isPrintMode]);

  const next = () => {
    goTo(state.active + 1);
  };

  const prev = () => {
    goTo(state.active - 1);
  };

  const resetFocus = () => {
    state.listElement
      .getElementsByClassName(styles.thumbnail)[state.active]
      .firstElementChild
      .focus();
  };

  const handleKeyDown = e => {
    if (isPrintMode)
      return;

    switch (e.which) {
      case 39:
        // Key RIGHT
        e.preventDefault();
        next();
        resetFocus();
        break;
      case 37:
        // Key Left
        e.preventDefault();
        prev();
        resetFocus();
        break;
      default:
    }
  };

  useEventObservable(resize$, resetLayout);

  useEffect(() => {
    goTo(index);
  }, [index]);

  useEffect(() => {
    state.listElement = containerRef.current.getElementsByClassName(styles.list)[0];
    state.btnPrevElement = containerRef.current.getElementsByClassName(styles.prev)[0];
    state.btnNextElement = containerRef.current.getElementsByClassName(styles.next)[0];

    const resetLayoutTimeout = setTimeout(resetLayout);
    return () => clearTimeout(resetLayoutTimeout);
  }, [items]);

  const PrevBtnIcon = state.active <= 0 ? BigArrowLeftPassiveIcon : BigArrowLeftIcon;
  const NextBtnIcon = state.active >= items.length - 1 ? BigArrowRightPassiveIcon : BigArrowRightIcon;

  return (
    <div className={styles.thumbnails}
      ref={containerRef}
      onKeyDown={handleKeyDown}
      role="presentation"
    >
      <div className={styles.cnt}>
        <Swipe
          onSwipeRight={prev}
          onSwipeLeft={next}
          allowMouseEvents
          className={styles.swipeArea}
        >
          <div className={styles.wrapper}>
            <ul className={styles.list}>
              {items.map((item, index) => (
                <li key={index}
                  className={styles.thumbnail}
                  style={{display: index===0 && !showFirstThumbnail ? 'none' : '' }}
                >
                  <button
                    tabIndex={state.active === index ? '0' : '-1'}
                    onClick={() => goTo(index)}
                    onKeyUp={e => e.key === 'Enter' && goTo(index)}
                    aria-keyshortcuts="ArrowRight ArrowLeft"
                  >
                    <Thumbnail item={item} active={state.active === index} />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </Swipe>
        <div className={styles.navControls}>
          <button className={styles.prev} onClick={prev}>
            <span className={styles.iconWrapper}>
              <PrevBtnIcon className={styles.icon} />
            </span>
          </button>
          <button className={styles.next} onClick={next}>
            <span className={styles.iconWrapper}>
              <NextBtnIcon className={styles.icon} />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

Thumbnails.propTypes = {
  items: PropTypes.array.isRequired,
  index: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  showFirstThumbnail: PropTypes.bool,
};

export default memo(Thumbnails);

function getShiftSet(index, itemsNumber, itemsInView) {
  return index > itemsNumber - itemsInView ? itemsNumber - itemsInView : index;
}