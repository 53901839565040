import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { Container } from 'components/primitives/grid';
import PageBreadcrumb from 'components/objects/pageBreadcrumb';
import BackLink from './BackLink';
import { useIsB2BSite } from 'utils/hooks/orlight';

const DetailsB2B = loadable(() => import(/*webpackChunkName:"pdp"*/'./Details'));
const DetailsB2C = loadable(() => import(/*webpackChunkName:"pdp"*/'./b2c/Details'));

const DetailsPage = ({ preset, middleContent, footerContent }) => {

  const isB2BSite = useIsB2BSite();

  const Details = isB2BSite ? DetailsB2B : DetailsB2C;

  return(
  <>
    <Container>
      <PageBreadcrumb backLinkComponent={BackLink} />
    </Container>
    <Details preset={preset} middleContent={middleContent} footerContent={footerContent} />
    <div className="product-page-footer">
    </div>
  </>
  );
};

DetailsPage.propTypes = {
  preset: PropTypes.string.isRequired,
  middleContent: PropTypes.array,
  footerContent: PropTypes.array,
};

export default DetailsPage;
