export const COUNTRIES_REQUESTED = 'CREDITACCOUNTUPGRADE/COUNTRIES_REQUESTED';
export const requestCountries = () => ({
  type: COUNTRIES_REQUESTED,
});

export const COUNTRIES_RECEIVED = 'CREDITACCOUNTUPGRADE/COUNTRIES_RECEIVED';
export const countriesReceived = payload => ({
  type: COUNTRIES_RECEIVED,
  payload,
});

export const CREDIT_ACCOUNT_UPGRADE_REQUESTED = 'CREDITACCOUNTUPGRADE/UPGRADE_REQUESTED';
export const sendCreditAccountUpgradeRequest = (input, files) => {
  return {
    type: CREDIT_ACCOUNT_UPGRADE_REQUESTED,
    payload: { input, files },
  };
};

export const CREDIT_ACCOUNT_UPGRADE_DONE = 'CREDITACCOUNTUPGRADE/UPGRADE_DONE';
export const creditAccountUpgradeDone = payload => ({
  type: CREDIT_ACCOUNT_UPGRADE_DONE,
  payload,
});
  