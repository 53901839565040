import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { submitCallOffSchedule } from 'behavior/calloff';
import { SanaButton } from 'components/primitives/buttons';
import { useCanCallOffAnyAmount } from '../hooks';

const Step2Submit = ({ 
  callOffEmail, 
  linesForCallOff, 
  callOffId, 
  documentLines,
  shipmentDate,
  reference,
  effectiveDate,
  fetchingCallOffBody,
  finishedCallOffBody
 }) => {

  const dispatch = useDispatch();

  const lines = linesForCallOff;

  const canCallOffAnyAmount = useCanCallOffAnyAmount(effectiveDate, shipmentDate);

  const getDocumentLine = line => {
    const { id } = line;
    const [documentLine] = documentLines.filter(l => l.id === id);
    return documentLine;
  };

  const hasLinesToCallOff = 
    !!lines && 
    lines.length > 0 && 
    lines.filter(line => line.quantity > 0).length > 0;

  const hasLinesOverRemaining = lines
    .some(l => {
      console.log("l", l);
      console.log("documentLines", documentLines);
      const dl = getDocumentLine(l);
      console.log('dl', dl);
      if (!dl) {
        console.error("Could not find document line of the following line object:", l);
        return true;
      }
      return l.quantity > dl.quantities.remaining;
    });

  const hasLinesOverInventory = (!canCallOffAnyAmount) &&
    lines
      .some(l =>{ 
        const dl = getDocumentLine(l);
        if (!dl) {
          console.error("Could not find document line of the following line object:", l);
          return false;
        }
        return l.quantity > (dl.product.inventory + dl.qtyOnPurchaseOrder);
      });

  const canSubmit = !fetchingCallOffBody && finishedCallOffBody && hasLinesToCallOff && !hasLinesOverRemaining;

  const handleSubmit = () => {

    let modifiedRequestedLinesForCallOff = [];

    for (const lineForCallOff of linesForCallOff) {
      let docLine = getDocumentLine(lineForCallOff);
      if (!(docLine.dummy)) {
        modifiedRequestedLinesForCallOff.push(lineForCallOff);
      } else if (lineForCallOff.quantity > 0 && docLine.dummy) {
        // Fetch the components that are part of the dummy configuration,
        // And add them to call off request
        let componentsOfDummyConfig = [];
        if (docLine.configuratorId) {
          componentsOfDummyConfig = documentLines.filter((e) => e.configuratorId === docLine.configuratorId && e.id !== docLine.id);
        } else if (docLine.ledStripOrderingId) {
          componentsOfDummyConfig = documentLines.filter((e) => e.ledStripOrderingId === docLine.ledStripOrderingId && e.id !== docLine.id);
        }

        let componentsToCallOff = componentsOfDummyConfig.map((e) => {
          return {
            id: e.id,
            quantity: lineForCallOff.quantity * e.callOffMultiplier
          }
        })
        modifiedRequestedLinesForCallOff = modifiedRequestedLinesForCallOff.concat(componentsToCallOff);
      }

      
    }
    
    const linesToCallOff = modifiedRequestedLinesForCallOff
      .filter(line => line.quantity > 0)
      .map(line => {
        const documentLine = getDocumentLine(line);
        const { product } = documentLine;

        return {
          id: line.id,
          productId: product.id,
          quantity: line.quantity,
          shipmentDate,
        };
      });

    const payload = {
      callOffId,
      linesToCallOff,
      callOffEmail,
      reference,
    };
    
    dispatch(submitCallOffSchedule(payload));
  };

  return (
    <SanaButton textKey="Submit" disabled={!canSubmit} onClick={handleSubmit} />
  );
};

Step2Submit.propTypes = {
  callOffEmail: PropTypes.string.isRequired,
  linesForCallOff: PropTypes.array,
  callOffId: PropTypes.string.isRequired,
  documentLines: PropTypes.array.isRequired,
  shipmentDate: PropTypes.string.isRequired,
  effectiveDate: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
};

export default connect (
  ({
    callOff: {
      linesForCallOff,
      shipmentDate,
      reference,
      body: {
        lines: documentLines,
        id: callOffId,
        effectiveDate,
      },
      fetchingCallOffBody,
      finishedCallOffBody
    }, 
  }) => ({ linesForCallOff, callOffId, documentLines, shipmentDate, reference, effectiveDate, fetchingCallOffBody, finishedCallOffBody }),
)(Step2Submit);
