import { useEffect, useState } from 'react';
import { PageComponentNames } from 'behavior/pages';
import { useDispatch, useSelector } from 'react-redux';
import { 
  beginCallOffSchedule,
  requestCallOffHeader,
  requestCallOffBody,
  requestSendCallOffEmail,
  CALLOFF_TYPE_EMAILS,
  CALLOFF_TYPE_LINK,
} from 'behavior/calloff';
import { CallOffDetails } from './CallOffDetails';
import { Container } from 'components/primitives/grid';
import { Input } from 'components/primitives/form';
import { SanaButton } from 'components/primitives/buttons';
import { ValidationError } from 'components/primitives/validationError';
import { SimpleText } from 'components/sanaText';
  
const scheduleCallOffOrderPageRenderer = ({ orderId }) => {
  const dispatch = useDispatch();

  const { header, body } = useSelector(x => x.callOff);

  const [callOffEmail, setCallOffEmail] = useState('');
  const [callOffAuthCode, setCallOffAuthCode] = useState('');
  const [needEmail, setNeedEmail] = useState(false);
  const [needAuthCode, setNeedAuthCode] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);
	const [submittedAuth, setSubmittedAuth] = useState(false);
	const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    dispatch(beginCallOffSchedule());
    dispatch(requestCallOffHeader(orderId));
  }, []);

  useEffect(() => {
    if (!header || header===null) {
      return;
    }

    if (header.type===CALLOFF_TYPE_LINK) {
      dispatch(requestCallOffBody(orderId));
    }
  }, [header]);

	useEffect(() => {

		if (!submittedAuth) {
      return;
    }

		setNotFound(body==null);
	}, [body]);

  const handleCallOffEmailChanged = e => {
    setCallOffEmail(e.target.value);
  };

  const handleCallOffAuthCodeChanged = e => {
    setCallOffAuthCode(e.target.value);
  };

  const handleCallOffSendEmailClicked = () => {
    const noEmail = callOffEmail==='';
    setNeedEmail(noEmail);

    if (noEmail) {
      return;
    }

    setNeedEmail(false);
    setSentEmail(true);
    dispatch(requestSendCallOffEmail(orderId, callOffEmail));
  };

  const handleCallOffAuthClicked = () => {
    const noEmail = callOffEmail==='';
    const noAuthCode = callOffAuthCode==='';

    setNeedEmail(noEmail);
    setNeedAuthCode(noAuthCode);

    if (noEmail || noAuthCode) {
      return;
    }

    setNeedEmail(false);
    setNeedAuthCode(false);
		setSubmittedAuth(true);
    dispatch(requestCallOffBody(orderId, callOffEmail, callOffAuthCode));
  };

  return (
    <>
      <Container>
        <h1><SimpleText textKey="ScheduleCallOffOrder_Title" /></h1>

        {header===null &&
          <div>
            <span><SimpleText textKey="ScheduleCallOffOrder_NotFound" /></span>
          </div>}

        {body==null && header && header.type===CALLOFF_TYPE_EMAILS &&
        <div className={'SalesAgreements_verification'}>
          <div className={'SalesAgreements_verification_header'}>
            <span><SimpleText textKey="ScheduleCallOffOrder_Header" /></span>
          </div>
          <div className={'SalesAgreements_verification_email'}>
            <div className="form_row">
              <div className="label-container SalesAgreements_emailHeader">
                <label htmlFor="callOffEmail"> 
                  <SimpleText textKey="ScheduleCallOffOrder_EmailAddress" />
                </label>
              </div>
              <Input id="callOffEmail" type="text" 
                onChange={handleCallOffEmailChanged} 
                disabled={sentEmail}
              />
            </div>
            <div className={'SalesAgreements_verification_error form_row'}>
              <div className="label-container" />
              <div className="error-message input-container">
                {needEmail && <ValidationError textKey="ScheduleCallOffOrder_EmailRequired" />}
              </div>
            </div>
          </div>

          {!sentEmail && 
            <div>
              <SanaButton textKey="PlaceCallOffOrder_Submit"
                type="button"
                onClick={handleCallOffSendEmailClicked}
              />
            </div>}

          {sentEmail && 
            <>
              <div className="SalesAgreements_verification_header SalesAgreements_codeHeader">
                <span><SimpleText textKey="ScheduleCallOffOrder_SendingCode" /></span>
              </div>
              <div className={'SalesAgreements_verification_code'}>
                <div className="form_row">
                  <div className="label-container SalesAgreements_codeHeader">
                    <label htmlFor="callOffAuthCode"> 
                      <SimpleText textKey="ScheduleCallOffOrder_AuthCode" />
                    </label>
                  </div>
                  <Input id="callOffAuthCode" type="text" onChange={handleCallOffAuthCodeChanged} />
                </div>
                <div className="SalesAgreements_verification_error form_row">
                  <div className="label-container" />
                  <div className="error-message input-container">
                    {needAuthCode && <ValidationError textKey="ScheduleCallOffOrder_AuthCodeRequired" />}
                  </div>
                </div>                
              </div>
              <div>
                <SanaButton textKey="PlaceCallOffOrder_Submit"
                  type="button"
                  onClick={handleCallOffAuthClicked}
                />
              </div>
            </>}

        </div>}
				{notFound && 
					<div>
						<span><SimpleText textKey="ScheduleCallOffOrder_NotFound" /></span>
					</div>}
        {body && 
          <>
            <CallOffDetails agreement={body} callOffEmail={callOffEmail} />
          </>
        }
      </Container>
    </>
  );
};

export default {
  [PageComponentNames.ScheduleCallOffOrder]: scheduleCallOffOrderPageRenderer,
};