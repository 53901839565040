import type { Epic } from 'behavior/types';
import type { SalesAgreementAction } from './actions';
import type { StoreDependencies } from 'behavior/types';
import { ofType } from 'redux-observable';
import { merge, of, EMPTY } from 'rxjs';
import { switchMap, map, mergeMap, startWith } from 'rxjs/operators';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import {
  AGREEMENT_APPLY,
  AGREEMENT_CANCEL,
  agreementApplied,
  agreementCanceled,
  AGREEMENT_APPLIED,
  AGREEMENT_TERMS_APPLY,
  CONTACTS_GET,
  contactsReceived,
  ADD_CONTACTS,
  contactsAdded,
  REMOVE_CONTACTS,
  contactsRemoved,
  EDIT_CONTACT,
  contactEdited,
  RESEND_NOTIFICATION,
  notificationResent,
} from './actions';
import {
  applySalesAgreementMutation,
  cancelSalesAgreementMutation,
  applyTermsAutomaticallyMutation,
  getContactsQuery,
  addContactsMutation,
  removeContactsMutation,
  editContactMutation,
  resendNotificationsMutation,
} from './queries';
import { retryWithToast } from 'behavior/errorHandling';
import { navigateTo } from 'behavior/events';
import { RouteName, routesBuilder } from 'routes';
import {
  modifyBasket,
  BASKET_REMOVE_AGREEMENT,
} from 'behavior/basket/actions';
import { skipIfPreviewWithToast } from 'behavior/preview';
import { toasts } from 'behavior/toasts';

type SalesAgreementMutationResponse = {
  salesAgreement: {
    apply: { success: boolean };
  } & {
    cancel: { success: boolean };
  } & {
    applyTermsAutomatically: { success: boolean };
  };
}

const agreementsEpic: Epic<SalesAgreementAction> = (action$, state$, { api, logger }) => {
  const setLoading = setLoadingIndicator();
  const unsetLoading = unsetLoadingIndicator();

  const apply$ = action$.pipe(
    ofType(AGREEMENT_APPLY),
    skipIfPreviewWithToast(state$, { api } as StoreDependencies),
    switchMap(({ payload }) =>
      api.graphApi<SalesAgreementMutationResponse>(applySalesAgreementMutation, { agreementId: payload.salesAgreementId }).pipe(
        map(({ salesAgreement }) => {
          if (salesAgreement.apply.success)
            return agreementApplied(payload.salesAgreementId);

          throw new Error(`Sales agreement with ID/Title: "${payload.salesAgreementId}" is not available anymore.`);
        }),
      ),
    ),
  );

  const cancel$ = action$.pipe(
    ofType(AGREEMENT_CANCEL),
    switchMap(() =>
      api.graphApi<SalesAgreementMutationResponse>(cancelSalesAgreementMutation).pipe(
        map(() => agreementCanceled()),
      ),
    ),
  );

  const appliedAgreement$ = action$.pipe(
    ofType(AGREEMENT_APPLIED),
    switchMap(() => {
      const { routing } = state$.value;
      const routeData = routing.routeData;
      const routeName = routeData && routeData.routeName;

      if (routeName === RouteName.SalesAgreements)
        return of(navigateTo(routesBuilder.forBasket()));

      return EMPTY;
    }),
  );

  const applyTermsAutomatically$ = action$.pipe(
    ofType(AGREEMENT_TERMS_APPLY),
    switchMap(() =>
      api.graphApi<SalesAgreementMutationResponse>(applyTermsAutomaticallyMutation).pipe(
        mergeMap(() => [unsetLoading, modifyBasket([])]),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        startWith(setLoading),
      ),
    ),
  );

  const removeAgreement$ = action$.pipe(
    ofType(BASKET_REMOVE_AGREEMENT),
    switchMap(() =>
      api.graphApi<SalesAgreementMutationResponse>(cancelSalesAgreementMutation).pipe(
        mergeMap(() => [unsetLoading, modifyBasket([])]),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        startWith(setLoading),
      ),
    ),
  );

  const getContacts$ = action$.pipe(
    ofType(CONTACTS_GET),
    switchMap(({ payload }) =>
     api.graphApi<any>(getContactsQuery, { agreementId: payload.salesAgreementId }).pipe(
      mergeMap(data => [unsetLoading, contactsReceived(data)]),
      retryWithToast(action$, logger, _ => of(unsetLoading)),
      startWith(setLoading),
     ),
    ),
  );

  const addContacts$ = action$.pipe(
    ofType(ADD_CONTACTS),
    switchMap(({ payload }) =>
      api.graphApi<any>(addContactsMutation, { input: { salesAgreementId: payload.salesAgreementId, contacts: payload.contacts } }).pipe(
        mergeMap(data => [unsetLoading, contactsAdded(data)]),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        startWith(setLoading),
      ),
    ),
  );

  const removeContacts$ = action$.pipe(
    ofType(REMOVE_CONTACTS),
    switchMap(({ payload }) =>
      api.graphApi<any>(removeContactsMutation, { input: { salesAgreementId: payload.salesAgreementId, contacts: payload.contacts } }).pipe(
        mergeMap(data => [unsetLoading, contactsRemoved(data)]),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        startWith(setLoading),
      ),
    ),
  );

  const editContact$ = action$.pipe(
    ofType(EDIT_CONTACT),
    switchMap(({ payload }) => 
      api.graphApi<any>(editContactMutation, 
          { 
            input: { 
              salesAgreementId: payload.salesAgreementId, 
              recordId: payload.contact.recordId,
              emailAddress: payload.contact.emailAddress,
              mobilePhoneNo: payload.contact.mobilePhoneNo,
            },
          }).pipe(
        mergeMap(data => [unsetLoading, contactEdited(data)]),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        startWith(setLoading),
      ),
    ),
  );

  const resendNotification$  = action$.pipe(
    ofType(RESEND_NOTIFICATION),
    switchMap(({ payload }) => 
      api.graphApi<any>(resendNotificationsMutation, 
        {
          input: {
            salesAgreementId: payload.salesAgreementId,
            contacts: payload.contacts,
          },
        }).pipe(
          mergeMap(() => [unsetLoading, notificationResent()]),
          retryWithToast(action$, logger, _ => of(unsetLoading)),
          startWith(setLoading),
        ),
    ),
  );

  return merge(apply$, cancel$, appliedAgreement$, applyTermsAutomatically$, removeAgreement$, getContacts$, addContacts$, removeContacts$, editContact$, resendNotification$);
};

export default agreementsEpic;
