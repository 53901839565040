import type { HeaderState } from './types';
import type { HeaderAction } from './actions';
import { HEADER_LOADED } from './actions';
import { parseContent } from 'behavior/content';

export const initialState: HeaderState = {
  desktop: null,
  mobile: null,
  loaded: false,
};

export default (state: HeaderState = initialState, action: HeaderAction): HeaderState => {
  switch (action.type) {
    case HEADER_LOADED:
      return {
        desktop: action.payload?.desktop ? parseContent(action.payload.desktop) : null,
        mobile: action.payload?.mobile ? parseContent(action.payload.mobile) : null,
        loaded: true,
      };
    default:
      return state;
  }
};
