import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from 'components/objects/forms/SanaForm.module.scss';
import { Table } from 'components/primitives/table';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { SanaButton } from 'components/primitives/buttons';
import { SimpleText, useSanaTexts } from 'components/sanaText';
import { 
  requestCallOff,
  callOffSubmitComplete,
	CALLOFF_TYPE_EMAILS,
} from 'behavior/calloff';
import { clearBasket } from 'behavior/basket';
import { AddressSelection } from 'components/objects/checkout/base/address';
import { FormGroup, SanaForm, TextBoxField} from '../forms';
import { makeSimpleText } from 'utils/render';
import { useSimpleTexts } from 'components/sanaText';

const PlaceCallOff = () => {

  const { submitted, projectContacts, shippingAddresses } = useSelector(x => x.callOff);

  const [referenceNoLbl] = useSimpleTexts(['ReferenceNumber']).texts;

  const dispatch = useDispatch();

  const formikRef = useRef();

	const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState('');
  const [submitClicked, setSubmitClicked] = useState(false);

  // The input value cannot be changed by setClonedValueReferenceInput
  // It's just a way to get the value of it
  const [clonedValueReferenceInput, setClonedValueReferenceInput] = useState('');

  const isSubmitButtonDisabled = useMemo(() => {
    return (
      selectedContacts.length===0 ||
      selectedShippingAddress==='' ||
      !clonedValueReferenceInput ||
      submitClicked
    );
  }, [selectedContacts, selectedShippingAddress, clonedValueReferenceInput, submitClicked])

  useEffect(() => {
    if (submitted) {
      dispatch(clearBasket(true));
      dispatch(callOffSubmitComplete());
    }
  }, [submitted]);

  useEffect(() => {
    if (shippingAddresses===undefined)
    {
      return;
    }

    if (shippingAddresses.length===1)
    {
      setSelectedShippingAddress(shippingAddresses[0].id);
    }
  }, [shippingAddresses]);

	const checkNeedContacts = () => {
		const nc = selectedContacts.length===0;	
		return nc;
	};

	const handleContactCheckboxClick = (e, contactNo) => {
		let newContacts = selectedContacts
			.filter(x => x!==contactNo);

		if (selectedContacts.indexOf(contactNo)===-1) {
			newContacts = newContacts.concat(contactNo);
		}

		setSelectedContacts(newContacts);
	};

  const formOnChange = useCallback((element) => {
    let target = element.target;
    if (target.getAttribute("name") === "referenceNo") {
      setClonedValueReferenceInput(target.value);
    }
  }, []);

  const handleSubmitScheduleClick = () => {

    if (checkNeedContacts() || !clonedValueReferenceInput) {
      return;
    }

    setSubmitClicked(true);
    dispatch(requestCallOff(CALLOFF_TYPE_EMAILS, selectedContacts, selectedShippingAddress, clonedValueReferenceInput));
  };

  const onAddressSelection = e => {
    setSelectedShippingAddress(e);
  };

  return (
    <>
  <h1>
  <SimpleText textKey="PlaceCallOffOrder_Header" />
</h1>
<h2><SimpleText textKey="ShipToAddress" /></h2>
{shippingAddresses!==undefined &&
<AddressSelection addresses={shippingAddresses} onSelection={onAddressSelection} currentId={selectedShippingAddress} />}

<h2><SimpleText textKey="PlaceCallOffOrder_SelectContacts" /></h2>
<div>
<Table className="placecallofforder-contacts">
	<thead>
		<tr>
			<th><SimpleText textKey="PlaceCallOffOrder_Role" /></th>
			<th><SimpleText textKey="PlaceCallOffOrder_ContactName" /></th>
			<th><SimpleText textKey="PlaceCallOffOrder_EmailAddress" /></th>
			<th><SimpleText textKey="PlaceCallOffOrder_MobilePhoneNo" /></th>
			<th />
		</tr>
	</thead>
	<tbody>
		{projectContacts!==undefined && projectContacts.map(x => (
			<tr key={x.contactNo}>
				<td>{x.role}</td>
        <td>{x.contactName}</td>
				<td>{x.emailAddress}</td>
				<td>{x.mobilePhoneNo}</td>
				<td>
          <input type="checkbox" disabled={x.mobilePhoneNo===''} onChange={e => handleContactCheckboxClick(e, x.contactNo)} />
          {x.mobilePhoneNo==='' &&
          <>
            <br />
            <SimpleText textKey="PlaceCallOffOrder_NoMobilePhoneNo" />
          </>}
        </td>
			</tr>
		))}
	</tbody>
</Table>
<div>
  <SanaForm formikRef={formikRef} onSubmit={handleSubmitScheduleClick} onChange={formOnChange} name={"placeCallOff"}>
    <FormGroup
      fieldName="referenceNo"
      fieldTitle={referenceNoLbl}
      fieldComponent={TextBoxField}
      validation={{ //Fieldprops
        required: { message: 'Please enter a reference'}, // The error message should be a Sana text but skipped here for brevity.
      }}
      helpTextKey="HelpIconText_ReferenceNo"
      helpTextClassName="checkout-additional-info-help-icon"
    />

    
  </SanaForm>
  
</div>
<SanaButton 
  textKey="PlaceCallOffOrder_Submit" 
  type="button" 
  className={btnStyles.btnBig + ' ' + styles.btn + ' placecallofforder-submit'} 
  disabled={isSubmitButtonDisabled}
  onClick={handleSubmitScheduleClick}

/>
</div>

</>
  );
};

export { PlaceCallOff };