export interface AppSettings {
  productionItemRoutingNos: string;
}

export default function isProductProductionItem(appSettings: AppSettings, product: any){
  if (!(!!product)) {
    return false;
  }

  const { productionItemRoutingNos } = appSettings;
  const nos = productionItemRoutingNos.split(',').map(n => n.trim());
  const { routingNo, id } = product;
  return id === 'KIT DOWNLIGHT' || nos.some(n => n === routingNo);  
}

export const isProductMadeToOrder = isProductProductionItem;