import { memo } from 'react';
import { useSanaTexts } from 'components/sanaText';
import { useSelector } from 'react-redux';
import { makeRichText } from 'utils/render';
import { Link } from 'components/primitives/links';

const CopyrightTextBlock = () => {
  const { texts: [ footerCopyright, sanaCommerce ], loaded } = useSanaTexts(['Footer_Copyright', 'SanaCommerce']);
  const shopName = useSelector(state => state.settings.shopName);

  if (!loaded)
    return null;

  return (
    <span className="copyright">
      {/* Сlass 'copyright' is used in web tests. */}
      {makeRichText(footerCopyright, [new Date().getFullYear(), shopName, <Link url="https://www.sana-commerce.com/" target="_blank">{makeRichText(sanaCommerce)}</Link>])}
    </span>
  );
};

export default memo(CopyrightTextBlock);
