exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ConfirmationModal_modal-container{max-width:520px;width:100%;margin:0 auto}.ConfirmationModal_modal-buttons-block{text-align:center;padding:1.5em 0;margin-top:1.5em}.ConfirmationModal_continue{margin:0 1.64em}", ""]);

// exports
exports.locals = {
	"modal-container": "ConfirmationModal_modal-container",
	"modalContainer": "ConfirmationModal_modal-container",
	"modal-buttons-block": "ConfirmationModal_modal-buttons-block",
	"modalButtonsBlock": "ConfirmationModal_modal-buttons-block",
	"continue": "ConfirmationModal_continue"
};