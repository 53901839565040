export const CATALOGUENAVIGATIONLIST_PRODUCTS_GENERALINFO_REQUESTED = 'CATALOGUENAVIGATIONLIST_PRODUCTS_GENERALINFO_REQUESTED';
export const requestProductsGeneralInfo = (listPageId, options, appendProducts = false) => ({
  type: CATALOGUENAVIGATIONLIST_PRODUCTS_GENERALINFO_REQUESTED,
  payload: { listPageId, options, appendProducts },
});

export const CATALOGUENAVIGATIONLIST_PRODUCTS_GENERALINFO_LOADED = 'CATALOGUENAVIGATIONLIST_PRODUCTS_GENERALINFO_LOADED';
export const productsGeneralInfoLoaded = (products, appendProducts, pageSize, totalCount, sorting) => ({
  type: CATALOGUENAVIGATIONLIST_PRODUCTS_GENERALINFO_LOADED,
  payload: {
    products,
    appendProducts,
    pageSize,
    totalCount,
    sorting,
  },
});

export const CATALOGUENAVIGATIONLIST_CALCULATED_FIELDS_REQUESTED = 'CATALOGUENAVIGATIONLIST_CALCULATED_FIELDS_REQUESTED';
export const requestCalculatedFields = (options, variantsOnly) => ({
  type: CATALOGUENAVIGATIONLIST_CALCULATED_FIELDS_REQUESTED,
  payload: { options, variantsOnly },
});

export const CATALOGUENAVIGATIONLIST_PRODUCTS_UPDATED = 'CATALOGUENAVIGATIONLIST_PRODUCTS_UPDATED';
export const productsUpdated = products => ({
  type: CATALOGUENAVIGATIONLIST_PRODUCTS_UPDATED,
  payload: products,
});

export const CATALOGUENAVIGATIONLIST_VARIANTS_GENERALINFO_REQUESTED = 'CATALOGUENAVIGATIONLIST_VARIANTS_GENERALINFO_REQUESTED';
export const requestVariantsGeneralInfo = options => ({
  type: CATALOGUENAVIGATIONLIST_VARIANTS_GENERALINFO_REQUESTED,
  payload: { options },
});

export const CATALOGUENAVIGATIONLIST_VIEWMODE_CHANGED = 'CATALOGUENAVIGATIONLIST_VIEWMODE_CHANGED';
export const catalogueNavigationListViewModeChanged = newMode => ({
  type: CATALOGUENAVIGATIONLIST_VIEWMODE_CHANGED,
  payload: { newMode },
});

export const CATALOGUENAVIGATIONLIST_VARIANTS_REQUESTED = 'CATALOGUENAVIGATIONLIST_VARIANTS_REQUESTED';
export const requestCatalogueNavigationListVariants = pageId => ({
  type: CATALOGUENAVIGATIONLIST_VARIANTS_REQUESTED ,
  payload: { id: pageId },
});

export const CATALOGUENAVIGATIONLIST_VARIANTS_RECEIVED = 'CATALOGUENAVIGATIONLIST_VARIANTS_RECEIVED';
export const receivedCatalogueNavigationListVariants = data => ({
  type: CATALOGUENAVIGATIONLIST_VARIANTS_RECEIVED,
  payload: data,
});