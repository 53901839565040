import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import { 
  requestCallOffHeader,
  CALLOFF_TYPE_LINK,
  CALLOFF_TYPE_EMAILS,
}  from 'behavior/calloff';

const CallOffConfirmation = () => {

  const { callOffId, header } = useSelector(x => x.callOff);
  const [callOffLink, setCallOffLink] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    setCallOffLink(`${window.location.origin}/scheduleCallOffOrder/${callOffId}`);
    //dispatch(requestCallOffHeader(callOffId));
  }, [callOffId]);

  useEffect(() => {
  }, [header]);

  return (
    <>
      <h1><SimpleText textKey="PlaceCallOffOrder_ConfirmationTitle" /></h1>
      <div>
        <SimpleText textKey="PlaceCallOffOrder_ConfirmationHeader" />
      </div>
      {header?.type===CALLOFF_TYPE_EMAILS &&
        <div>
          <SimpleText textKey="PlaceCallOffOrder_EmailIntro" />
        </div>}
      {header?.type===CALLOFF_TYPE_LINK &&
        <div>
          <SimpleText textKey="PlaceCallOffOrder_LinkIntro" /> <a href={callOffLink}>{callOffLink}</a>
        </div>}
    </>
  );
};

export { CallOffConfirmation };