import styles from '../PLP.module.scss';
import PropTypes from 'prop-types';
import ProductContext from '../ProductContext';
import Description from '../Description';
import { WithProductClickTracking } from 'components/objects/analytics';
import WithCatalogueNavigationRoute from '../WithCatalogueNavigationRoute';
import { Link } from 'components/primitives/links';
import { Thumbnails } from './Thumbnails';

const ProductFamilyLine = ({ productFamily, index }) => {

  const { images: pageImages, pageId: pfPageId } = productFamily;

  const relevantImages = pageImages
    ?.filter(pi => pi.pageId===productFamily.pageId)
    .map(pi => ({ ...pi, url: (pi.medium === undefined || pi.medium==null) ? pi.small : pi.medium }))
    .filter(pi => pi.medium!==undefined && pi.medium!==null);

  const pf = { ...productFamily, isVariant: false };

  if (pfPageId==null || pf.url==='') {
    return null;
  }

  return (
    <ProductContext.Provider value={pf}>
      <div className={styles.item}>
        <div className={styles.tile} role="listitem">
          <i className="visually-hidden">{index + 1}</i>
          <div style={{ width: '100%', position: 'relative' }}>
            {
              <Thumbnails images={relevantImages} pageId={pfPageId} pageUrl={pf.url} isVariant={pf.isVariant} />
            }
          </div>
          <div className={styles.productInfoWrapper}>
            <div className={styles.productInfo}>
              <Description />
              <div className="catalogue-navigation-description">
                {pf.description}
              </div>

              <WithProductClickTracking>
                {handleClick => (
                  <WithCatalogueNavigationRoute productFamily={{ pageId: pfPageId, url: pf.url, isVariant: false }}>
                    {(productUrl, productRouteBuilder) => (
                      <Link onClick={handleClick} url={productUrl} to={productRouteBuilder}>
                        <div className="catalogue-navigation-caption">
                          {pf.caption}
                        </div>
                      </Link>
                    )}
                  </WithCatalogueNavigationRoute>
                )}
              </WithProductClickTracking>

              <div className={styles.productAction} />
            </div>
          </div>
        </div>
      </div>
    </ProductContext.Provider>
  );
};

ProductFamilyLine.propTypes = {
  productFamily: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default ProductFamilyLine;