import styles from '../MainNav.module.scss';
import headerStyles from 'components/sections/header/Header.module.scss';
import { useEffect, useLayoutEffect, useRef, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'components/primitives/grid';
import MainNav from '../MainNav';
import NavItem from './NavItem';
import NavDimensionsProvider from '../NavDimensionsProvider';
import SublistPositionProvider from '../SublistPositionProvider';
import { fillGapSelectors } from 'components/objects/modals';
import { useIsMobileSafari, isBrowser } from 'utils/detections';
import { hoveredListClass } from '../eventHandlers';
import scrollIntoView from 'scroll-into-view';
import { enablePageScroll, disablePageScroll, addFillGapSelector, removeFillGapSelector, getPageScrollBarWidth } from 'scroll-lock';
import { useLayoutShifter } from 'utils/layout';
import { useNavMenuContext } from '../hooks';
import { ComponentGroup } from 'behavior/navigation/constants';

const { modalMode } = headerStyles;
// data-scroll-lock-scrollable - attribute that allows scrolling on and inside of element when body scroll disabled
const scrollableAttr = 'data-scroll-lock-scrollable';
const iOSBottomIndent = +styles.iOSBottomIndent;

const ModalNav = ({
  visible,
  forDesktop,
  noGutters,
  handleSkipLinkClick,
}) => {
  const { componentGroup } = useNavMenuContext();
  const isMultiColumnMenu = componentGroup === ComponentGroup.MultiColumnMainMenu;
  const { bottomFixedElementsHeight } = useLayoutShifter();
  const navRef = useRef(null);
  const containerRef = useRef(null);
  const isMobileSafari = useIsMobileSafari();

  const wrapperStyle = useMemo(() => {
    if (!isBrowser || visible)
      return null;

    const pageScrollBarWidth = getPageScrollBarWidth();
    if (pageScrollBarWidth)
      return { marginRight: `-${pageScrollBarWidth}px` };
  }, [visible]);

  useEffect(() => {
    if (!containerRef.current || !isMobileSafari)
      return;

    // Additional bottom indent for iOS Safari to ensure menu content is not hidden below actual device screen.
    containerRef.current.parentElement.style.paddingBottom = `${Math.abs(bottomFixedElementsHeight - iOSBottomIndent)}px`;
  }, [isMobileSafari, bottomFixedElementsHeight]);

  useLayoutEffect(
    handleNavVisibilityChange.bind(
      null,
      visible,
      containerRef.current,
      navRef.current,
      isMultiColumnMenu,
    ),
    [visible, isMultiColumnMenu],
  );

  const wrapperClassNames = `${styles.modalNavWrapper} ${visible ? styles.visible : ''} ${isMultiColumnMenu ? styles.limitedHeight : styles.scrollable}`;

  return (
    <section
      className={wrapperClassNames}
      style={wrapperStyle}
      data-scroll-lock-fill-gap={(isMultiColumnMenu && forDesktop) || null}
      aria-hidden={!visible}
      aria-expanded={visible}
    >
      <Container
        fluid={!forDesktop}
        className={`${styles.navContainer}${noGutters ? ` ${styles.noGutters}` : ''}`}
        ref={containerRef}
      >
        <NavDimensionsProvider navRef={navRef}>
          <SublistPositionProvider>
            <MainNav
              ref={navRef}
              NavItemComponent={NavItem}
              navClass={`${styles.modal} ${isMultiColumnMenu ? styles.multicolumn : styles.simple}`}
              handleSkipLinkClick={handleSkipLinkClick}
              omitSkipLinkScroll
              id="mainNavigation"
            />
          </SublistPositionProvider>
        </NavDimensionsProvider>
      </Container>
    </section>
  );
};

ModalNav.propTypes = {
  visible: PropTypes.bool,
  forDesktop: PropTypes.bool,
  noGutters: PropTypes.bool,
  handleSkipLinkClick: PropTypes.func.isRequired,
};

export default memo(ModalNav);

function handleNavVisibilityChange(isVisible, navContainerElement, navRefElement, isMultiColumnMenu) {
  if (!navContainerElement || !navRefElement)
    return;

  const [navListRootElement] = navRefElement.children;
  const { parentElement: scrollableContainer } = navContainerElement;
  const { parentElement: stickyHeaderWrapper } = scrollableContainer;
  let navListRootObserver;

  if (isVisible) {
    stickyHeaderWrapper.classList.add(modalMode); // Set modal mode styles for sticky header wrapper element
    if (isMultiColumnMenu) {
      navListRootElement.setAttribute(scrollableAttr, true);
      navListRootObserver && navListRootObserver.disconnect();
    } else {
      const mutationOptions = { attributes: true, attributeFilter: ['class'] };
      scrollableContainer.setAttribute(scrollableAttr, true);
      scrollableContainer.scrollTop = 0;
      navListRootObserver = createNavListRootMutationObserver(scrollableContainer);
      navListRootObserver.observe(navListRootElement, mutationOptions);
    }
    disableBodyScroll();
  }

  return () => {
    navListRootObserver && navListRootObserver.disconnect();
    stickyHeaderWrapper.classList.remove(modalMode);
    scrollableContainer.removeAttribute(scrollableAttr);
    navListRootElement.removeAttribute(scrollableAttr);
    if (document.body.hasAttribute('data-scroll-lock-locked'))
      enableBodyScroll();
  };
}

function disableBodyScroll() {
  disablePageScroll();
  addFillGapSelector(fillGapSelectors);
}

function enableBodyScroll() {
  enablePageScroll();
  removeFillGapSelector(fillGapSelectors);
}

function createNavListRootMutationObserver(scrollableContainer) {
  // If simple view, all sublists closed and navigation list root element
  // is not in the viewport scroll list root into view
  const validTarget = target => target === scrollableContainer;
  return new MutationObserver(mutations => {
    const [classAttrMutation] = mutations;
    const { target } = classAttrMutation;
    if (target.classList.contains(hoveredListClass))
      return;

    const topOffset = parseInt(scrollableContainer.parentElement.style.paddingTop, 10) || 0;
    const { bottom } = target.getBoundingClientRect();

    if (bottom > topOffset)
      return;

    scrollIntoView(target, {
      time: 200, validTarget, align: { top: 0, topOffset },
    });
  });
}
