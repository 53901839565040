import { loadProductListPageQuery } from './queries';
import { tap, map, first, switchMap, pluck } from 'rxjs/operators';
import { of } from 'rxjs';
import { initComponent } from 'behavior/pages/helpers';
import { parseContent } from 'behavior/content';
import { navigateTo } from 'behavior/events';
import { ProductSpecificationFilter } from 'behavior/products/product';
import { sortOptionsAreEqual, getViewModeProductAmount } from './helpers';
import { PageComponentNames } from '../componentNames';

export default ({ params, options }, state$, { api, scope }) => {
  const { id } = params;
  if (!id)
    return of(null);

  const handle = ({ settings }) => {
    const viewMode = params.viewMode;
    const state = state$.value;
    const loadOptions = createLoadOptions(params, { ...options, viewMode }, settings.productList, state);

    const { 
      routing: { 
        routeData,
        previous,
      },
    } = state;

    return api.graphApi(loadProductListPageQuery({
      isInsiteEditor: state.insiteEditor.initialized,
    }), {
      id,
      options: loadOptions,
      specificationFilter: ProductSpecificationFilter.ForList,
      loadLargeImages: scope === 'SERVER',
      loadCategories: state.analytics && state.analytics.isTrackingEnabled,
    }).pipe(
      pluck('pages', 'catalogueNavigation'),
      initComponent(PageComponentNames.CatalogueNavigation),
      tap(result => parsePageContent(result, params, state)),
      map(result => {
        const page = result && result.page;
        if (!page)
          return null;

        page.lastViewedEnabled = settings.lastViewedEnabled;

        page.reset = shouldResetCataloguePage({
          params,
          state,
          previous,
          loadOptions
        })

        return { page };
      }),
    );
  };

  if (state$.value.settings.loaded)
    return handle(state$.value);

  return state$.pipe(
    first(({ settings }) => settings.loaded),
    switchMap(handle),
  );
};

const isSamePage = (params, state) => {
  const { id } = params;
  const { page: { id: pageId } } = state;

  return id===pageId;
};

/**
 * It indicates if the content of the page should be reset/cleaned.
 * @param {Object} info Required information
 * @param {Object} info.params Page Params
 * @param {Object} info.state Redux State
 * @param {Object} info.previous "previous" state from Redux. Relates to the previous route.
 */
function shouldResetCataloguePage(info){
  const { params, state, previous } = info;
  let isNotSamePage = !isSamePage(params, state);
  return !isNotSamePage && previous?.routeData.routeName !== 'CatalogueNavigation';
}

function parsePageContent(result, params, state) {
  const page = result && result.page;

  const { page: pageState } = state;

  if (!page)
    return;

  const { 
    productVariants: { 
      variants: newVariants,
    },
    productFamilies: {
      families: newFamilies,
    },
    productVariantImages: newVariantImages,
  } = page;

  normalizePage(page);

  page.headerContent = parseContent(page.headerContent);
  page.footerContent = parseContent(page.footerContent);

  const { sort, viewMode, page: index, id } = params;

  const productVariants = pageState.productVariants?.variants;
  const productFamilies = pageState.productFamilies?.families;
  const productVariantImages = pageState.productVariantImages;

  let samePage = isSamePage(params, state); //= id===pageState.id;

  if (samePage && productFamilies!==undefined) {
    const [firstFamily] = productFamilies;
    const [firstNewFamily] = newFamilies;

    if (firstFamily!==undefined && firstNewFamily!==undefined && firstFamily.pageId===firstNewFamily.pageId) {
      samePage = false;
    }
  }

  const { viewMode: existingViewMode } = pageState;

  const existingVariants = (productVariants === undefined || !samePage) ? [] : productVariants;
  const existingFamilies = (productFamilies === undefined || !samePage) ? [] : productFamilies;
  const existingVariantImages = (productVariantImages === undefined || !samePage) ? [] : productVariantImages;

  page.productVariants.variants = [...existingVariants, ...newVariants];
  page.productFamilies.families = [...existingFamilies, ...newFamilies];
  page.productVariantImages = [...existingVariantImages, ...newVariantImages];

  page.viewMode = existingViewMode===undefined ? viewMode : existingViewMode;

  page.selectedSorting = sort || page.defaultSorting;
  page.selectedViewMode = viewMode || page.defaultViewMode;
  page.id = id;

  // Prevent page indexing by robots in case of
  // sorting or view mode is not default
  // or paging is present in URL.
  // or selected facet is not crawlable
  page.index = (!sort || sortOptionsAreEqual(sort, page.defaultSorting))
    && page.selectedViewMode === page.defaultViewMode
    && !index;
}

export function createLoadOptions(params, options, settings, state) {
  const { sort, facets, q } = params;

  const paramsPage = params.page;
  const page = options.page || (paramsPage ? paramsPage - 1 : 0);
  const count = options.size || params.count;
  const viewMode = options.viewMode;

  const loadOptions = {
    page: {
      index: page || 0,
      size: count || getViewModeProductAmount(viewMode, settings),
    },
  };

  if (sort)
    loadOptions.sorting = [sort];

  if (facets) {
    const facetsArr = Object.entries(facets).map(([name, values]) => ({ name, values }));
    if (facetsArr.length)
      loadOptions.facets = { filter: facetsArr };
  }

  if (q)
    loadOptions.keywords = q;

  return loadOptions;
}

function normalizePage(page) {
  const emptyVariants = { variants: [], totalCount: 0 };
  const productVariants = page.productVariants === undefined ? emptyVariants : page.productVariants;

  const { totalCount: familiesTotalCount } = page.productFamilies;
  const { totalCount: variantsTotalCount } = productVariants;

  //page.productFamilies = families;
  page.totalCount = page.isProductFamilyListPage ? familiesTotalCount : variantsTotalCount;
}
