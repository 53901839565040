import { initSystemPageContent, loadSystemPageQuery } from 'behavior/pages/system';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { map } from 'rxjs/operators';
import { scheduleCallOffPageQuery } from './queries';

export default (routeData, _state$, { api }) => {
  const { params: { orderId } } = routeData;

    return api.graphApi(scheduleCallOffPageQuery, { orderId }).pipe(
      map(({ pages: { scheduleCallOffOrder } }) => ({
        page: {
          ...scheduleCallOffOrder,
          orderId,
          component: PageComponentNames.ScheduleCallOffOrder,
        },
      })),
      initSystemPageContent(),
    );
};
