import type { NewsHeadlines } from './types';

export const NEWS_HEADLINES_LOAD = 'NEWS_HEADLINES/LOAD' as const;
export const loadNewsHeadlines = (maxCount: number) => ({
  type: NEWS_HEADLINES_LOAD,
  payload: { maxCount },
});

export const NEWS_HEADLINES_LOADED = 'NEWS_HEADLINES/LOADED' as const;
export const newsHeadlinesLoaded = (items: NewsHeadlines) => ({
  type: NEWS_HEADLINES_LOADED,
  payload: { items },
});

export type NewsHeadlinesAction = ReturnType<
  | typeof loadNewsHeadlines
  | typeof newsHeadlinesLoaded
>
