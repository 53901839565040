import { setLoadingIndicator } from 'behavior/loadingIndicator';

export const CALLOFF_BEGIN = 'CALLOFF_BEGIN';
export const beginCallOff = projectId => ({
  type: CALLOFF_BEGIN,
	payload: { projectId },
});

export const CALLOFF_BEGIN_RESPONSE = 'CALLOFF_BEGIN_RESPONSE';
export const beginCallOffResponse = contacts => ({
	type: CALLOFF_BEGIN_RESPONSE,
	payload: { contacts },
});

export const CALLOFF_SUBMIT_REQUEST = 'CALLOFF_SUBMIT_REQUEST'; 
export const requestCallOff = (callOffType, contactNos, selectedShippingAddress, referenceNo) => {
 setLoadingIndicator();
 const result = {
  type: CALLOFF_SUBMIT_REQUEST,
  payload: { callOffType, contactNos, selectedShippingAddress, referenceNo },
 };

 return result;
};

export const CALLOFF_SEND_EMAIL_REQUEST = 'CALLOFF_SEND_EMAIL_REQUEST';
export const requestSendCallOffEmail = (callOffId, emailAddress) => ({
  type: CALLOFF_SEND_EMAIL_REQUEST,
  payload: { callOffId, emailAddress },
});

export const CALLOFF_SEND_EMAIL_RESPONSE = 'CALLOFF_SEND_EMAIL_RESPONSE';
export const sendCallOffEmailResponse = () => ({
  type: CALLOFF_SEND_EMAIL_RESPONSE,
});

export const CALLOFF_SUBMIT_RESPONSE = 'CALLOFF_SUBMIT_RESPONSE';
export const callOffSubmitResponse = callOffId => ({
  type: CALLOFF_SUBMIT_RESPONSE,
  payload: { callOffId },
});

export const CALLOFF_HEADER_REQUEST = 'CALLOFF_HEADER_REQUEST';
export const requestCallOffHeader = callOffId => ({
  type: CALLOFF_HEADER_REQUEST,
  payload: { callOffId },
});

export const CALLOFF_HEADER_RESPONSE = 'CALLOFF_HEADER_RESPONSE';
export const callOffHeaderResponse = payload => ({
  type: CALLOFF_HEADER_RESPONSE,
  payload,
});

export const CALLOFF_BODY_REQUEST = 'CALLOFF_BODY_REQUEST';
export const requestCallOffBody = (callOffId, email, authCode) => ({
  type: CALLOFF_BODY_REQUEST,
  payload: { callOffId, email, authCode, shipmentDate: new Date() },
});

export const CALLOFF_BODY_RESPONSE = 'CALLOFF_BODY_RESPONSE';
export const callOffBodyResponse = payload => ({
  type: CALLOFF_BODY_RESPONSE,
  payload,
});

export const CALLOFF_SUBMIT_COMPLETE = 'CALLOFF_SUBMIT_COMPLETE';
export const callOffSubmitComplete = () => ({
 type: CALLOFF_SUBMIT_COMPLETE, 
});

export const CALLOFF_SCHEDULE_BEGIN = 'CALLOFF_SCHEDULE_BEGIN';
export const beginCallOffSchedule = () => ({
  type: CALLOFF_SCHEDULE_BEGIN,
});

export const CALLOFF_SUBMIT_SCHEDULE = 'CALLOFF_SUBMIT_SCHEDULE';
export const submitCallOffSchedule = payload => ({
  type: CALLOFF_SUBMIT_SCHEDULE,
  payload,
});

export const CALLOFF_SUBMIT_SCHEDULE_RESPONSE = 'CALLOFF_SUBMIT_SCHEDULE_RESPONSE';
export const submitCallOffScheduleResponse = payload => ({
  type: CALLOFF_SUBMIT_SCHEDULE_RESPONSE,
  payload,
});

export const CALLOFF_SCHEDULE_SUBMIT_STEP_1 = 'CALLOFF_SCHEDULE_SUBMIT_STEP_1';
export const submitCallOffScheduleStep1 = payload => ({
  type: CALLOFF_SCHEDULE_SUBMIT_STEP_1,
  payload,
});

export const CALLOFF_SCHEDULE_SUBMIT_STEP_1_RESPONSE = 'CALLOFF_SCHEDULE_SUBMIT_STEP_1_RESPONSE';
export const submitCallOffScheduleStep1Response = payload => ({
  type: CALLOFF_SCHEDULE_SUBMIT_STEP_1_RESPONSE,
  payload,
});

export const CALLOFF_QUANTITY_CHANGED = 'CALLOFF_QUANTITY_CHANGED';
export const calloffQuantityChanged = payload => ({
  type: CALLOFF_QUANTITY_CHANGED,
  payload,
});

export const CALLOFF_BACK_TO_STEP_1 = 'CALLOFF_BACK_TO_STEP_1';
export const backToStep1 = () => ({
  type: CALLOFF_BACK_TO_STEP_1,
});