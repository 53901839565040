import { useSelector } from 'react-redux';
import styles from '../PLP.module.scss';
import PropTypes from 'prop-types';
import ProductContext from '../ProductContext';
import Description from '../Description';
import { WithProductClickTracking } from 'components/objects/analytics';
import { Link } from 'components/primitives/links';
import WithCatalogueNavigationRoute from '../WithCatalogueNavigationRoute';
import { Thumbnails } from './Thumbnails';

const ProductVariantLine = ({ productVariant, index }) => {

  const pv = { ...productVariant, isVariant: true };
  const { 
    pageId: pvPageId, 
    productFamilyCode, 
    productVariantCode, 
    colours,
    attributeRange1,
    attributeRange2,
    attributeRange3,
    attributeRange4,
  } = pv;

  const { productVariantImages: images } = useSelector(s => s.page);

  const relevantImages = images
    .filter(i => {
      const imageName = i.small===undefined ? i.medium : i.small;

      if (imageName===undefined || imageName==null) {
        return false;
      }

      if (i.variantId===productVariantCode && i.productFamilyCode===productFamilyCode) {
        return true;
      }

      const [imageFileName] = imageName.toLowerCase().replace(/%20/g, ' ').split('/').slice(-1);
      const fileNameSplit = imageFileName.split('_');
      const [imageFamily, imageVariant] = fileNameSplit.slice(1, 3);

      return imageFamily===productFamilyCode.toLowerCase() && 
        imageVariant===productVariantCode.toLowerCase();
    })
    .map(i => ({ ...i }))
    .filter(i => i.medium!==undefined && i.medium!==null);

  const nonEmptyAttributes = [attributeRange1, attributeRange2, attributeRange3, attributeRange4]
    .filter(ar => ar!=='');

  const hasAttributes = nonEmptyAttributes.length>0;

  const attributeRanges = ['','','','']
    .concat(nonEmptyAttributes.reverse())
    .reverse()
    .slice(0,4);

  return (
    <ProductContext.Provider value={pv}>
      <div className={styles.item}>
        <div className={styles.tile} role="listitem">
          <i className="visually-hidden">{index + 1}</i>
          <div style={{ width: '100%', position: 'relative' }}>
            {
              <Thumbnails images={relevantImages} pageId={pv.pageId} pageUrl={pv.url} isVariant={pv.isVariant} />
            }
          </div>
          <div className={styles.productInfoWrapper}>
            <div className={styles.productInfo}>
              <Description />
              <div className="catalogue-navigation-description">
                {pv.description}
              </div>

              <WithProductClickTracking>
                {handleClick => (
                  <WithCatalogueNavigationRoute productFamily={{ pageId: pvPageId, url: pv.url, isVariant: pv.isVariant }}>
                    {(productUrl, productRouteBuilder) => (
                      <Link onClick={handleClick} url={productUrl} to={productRouteBuilder}>
                        <div className="catalogue-navigation-caption">
                          {pv.caption}
                        </div>
                      </Link>
                    )}
                  </WithCatalogueNavigationRoute>
                )}
              </WithProductClickTracking>

              <div className="product-variant-attribute-range-container">
                {hasAttributes && attributeRanges.map((ar, i) => (
                  <div key={i} className={ar==='' ? '' : styles.productVariantAttributeRange}>
                    {ar}
                  </div>
                ))}
              </div>
              <div className={styles.productVariantColourContainer}>
                {colours.map((c,i) =>  
                  <div key={i} className={styles.productVariantColourSwatch} style={{ 'backgroundColor': c }} />
                )}
              </div>
              <div className={styles.productAction} />
            </div>
          </div>
        </div>
      </div>
    </ProductContext.Provider>
  );
};

ProductVariantLine.propTypes = {
  productVariant: PropTypes.object.isRequired,
  gridMode: PropTypes.bool,
  showThumbnail: PropTypes.bool,
  index: PropTypes.number.isRequired,
};

export default ProductVariantLine;