const useGetGroupArrayByKey = () => {
  const f = (array, key) => {
    const uniqueKeys = [...new Set(array.map(item => item[key]))];

    const groups = uniqueKeys.map(uk => array.filter(item => item[key] === uk));
    return groups;
  };

  return f;

};

export default useGetGroupArrayByKey;