import { AbilityTo } from 'behavior/user/constants';
import { useHasAbilities } from 'components/objects/user';
import { useSimpleTexts } from 'components/sanaText';
import { useIsProductionItem } from 'utils/hooks/orlight';

const useShowInDate = ({ product }) => {

  if(!product) {
    return false;
  }
  const canViewStock = useHasAbilities(AbilityTo.ViewStock)[0];

  const [dueInDateTemplate] = useSimpleTexts(['Product_DueIn_Template']).texts;
  const { inventory, dueInDate, isOrderable } = product; 

  const isProductionItem = useIsProductionItem(product);
  const [todayIsoDate] = new Date().toISOString().split('T');
  const hasEmptyDate = !dueInDate || dueInDate === '0001-01-01' || dueInDate === todayIsoDate;

  if (inventory>0 
    || !(!!dueInDateTemplate) 
    || !(!!dueInDate)
    || isProductionItem
    || hasEmptyDate
    || !canViewStock
    || !isOrderable) {

    return false;
  }

  return true;

};

export default useShowInDate;