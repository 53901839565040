export const CHALLENGE_VERIFICATION_REQUEST = 'CHALLENGE_VERIFICATION_REQUEST'; 
export const requestChallengeVerification = challengeCode => {
 const result = {
  type: CHALLENGE_VERIFICATION_REQUEST,
  payload: { challengeCode },
 };

 return result;
};

export const CHALLENGE_SUBMIT_RESPONSE = 'CHALLENGE_SUBMIT_RESPONSE';
export const challengeSubmitResponse = challengeAuthenticated => ({
  type: CHALLENGE_SUBMIT_RESPONSE,
  payload: { challengeAuthenticated },
});

export const CHALLENGE_RESET = 'CHALLENGE_RESET';
export const challengeReset = () => ({
  type: CHALLENGE_RESET,
});