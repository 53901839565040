import styles from './PageTemplate.module.scss';
import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'components/primitives/grid';
import HeaderContent from './HeaderContent';
import { PageContent } from 'components/objects/pages';
import { FacetsProvider, facetUrlBuilder } from '../facets';
import { plpId } from '../constants';
import { HashRoutingContext } from 'components/hash';
import { connectToContext } from 'utils/react';

const PageTemplate = ({ facets, headerContent, footerContent, children, navigateTo }) => {
  const shouldHideLeftColumn = true;//isEmpty && loaded && !leftColumnProductList;

  const scrollToRoot = () => {
    const root = document.getElementById('root');
    if (!!root) {
      root.scrollIntoView();
    } else {
      console.log('Could not find root -- retrying in 1sec');
      setTimeout(scrollToRoot(), 1000);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = (
    <Container className={`${styles.plp} ${shouldHideLeftColumn ? styles.hideLeftColumn : ''}`}>
      <Row>
        <Col id={plpId} className={`${styles.productList} navigation-list`}>
          {children}
        </Col>
      </Row>
    </Container>
  );
  const renderFacets = !!facets && facets.facets.length > 0;

  return (
    <>
      {renderFacets
        ? (
          <FacetsProvider facets={facets} urlBuilder={facetUrlBuilder}>
            <HeaderContent headerContent={headerContent} />
            {content}
          </FacetsProvider>
        )
        : (
          <>
            <HeaderContent headerContent={headerContent} />
            {content}
          </>
        )}
      <PageContent content={footerContent} position="footer" />
    </>
  );
};

PageTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  facets: PropTypes.object,
  headerContent: PropTypes.any,
  footerContent: PropTypes.array,
  id: PropTypes.string,
  isEmpty: PropTypes.bool,
};

const memoizedPageTemplate = memo(PageTemplate);

memoizedPageTemplate.selectProps = (({
  facets,
  id,
  headerContent,
  footerContent,
  productFamilies,
}) => ({
  facets,
  id,
  headerContent,
  footerContent,
  isEmpty: !productFamilies.length,
})
);

//export default memoizedPageTemplate;

export default connectToContext(
  [HashRoutingContext],
  ({ navigateTo }) => ({ navigateTo }),
)(memoizedPageTemplate);