import styles from '../PLP.module.scss';
import { useContext } from 'react';
import { RichText, useSanaTexts } from 'components/sanaText';
import { OfflinePriceWarning } from 'components/primitives/product';
import Schema from '../Schema';
import { ViewModes } from 'behavior/pages/productList';
import { FacetsContext } from '../facets';
import { LastViewedProducts } from 'components/objects/lastViewed';
import withPageProps, { pagePropTypes } from '../withPageProps';
import { useAriaStatusNotification } from '../hooks';
import { HeaderRow } from '../HeaderRow';
import { trackProductListView, EventSource } from 'behavior/analytics';
import { connect } from 'react-redux';
import { useMatchMediaContext } from 'components/responsive';
import { default as ProductFamiliesView } from './ProductFamiliesView';
import { default as ProductVariantsView } from './ProductVariantsView';

const Page = ({
  totalCount,
  selectedSorting,
  selectedViewMode,
  showThumbnails,
  id,
  lastViewedEnabled,
  relevanceAvailable,
  pageTitle,
  productFamilies,
  productVariants,
  isProductFamilyListPage,
  isProductVariantListPage,
}) => {
  const { hasFacets, availableFacets } = useContext(FacetsContext);
  const [headerLbl] = useSanaTexts(['Aria_PLP_Header']).texts;
  const trackingSource = id === 'search' ? EventSource.SearchResult : { value: pageTitle };

  const overriddenViewMode = ViewModes.Grid;
  const gridMode = selectedViewMode === ViewModes.Grid || true;
  const { xl } = useMatchMediaContext();

  const { variants } = productVariants;
  const { families } = productFamilies;

  const hasSomethingToShow = () => {
    if (isProductFamilyListPage) {
      return families.length>0;
    }

    if (variants===undefined) {
      return true; // still waiting for variants to load.
    }
    return variants.length>0;
  };

  useAriaStatusNotification(productFamilies, selectedViewMode, selectedSorting);

  if (!hasSomethingToShow()) {
    return (
      <div className="msg-block">
        <RichText textKey="ProductList_NoResult" />
      </div>
    );
  }

  return (
    <>
      <Schema productFamilies={productFamilies} />
      <OfflinePriceWarning />
      <div className={styles.panel}>
        <header className={styles.panelHeader} aria-label={headerLbl}>
          <HeaderRow
            key={id}
            totalCount={totalCount}
            selectedSorting={selectedSorting}
            sortingEnabled={false}
            relevanceAvailable={relevanceAvailable}
            hasFacets={hasFacets}
            availableFacets={availableFacets}
            viewModeSwitchEnabled={false}
            selectedViewMode={overriddenViewMode}
          />
        </header>
        {isProductFamilyListPage && 
          <ProductFamiliesView 
            productFamilies={productFamilies} 
            productVariants={productVariants}
            trackingSource={trackingSource} 
            gridMode={gridMode}
            showThumbnails={showThumbnails}
            id={id}
          />
        }
        {isProductVariantListPage &&
          <ProductVariantsView 
            productVariants={variants}
            trackingSource={trackingSource}
            gridMode={gridMode} 
            showThumbnails={showThumbnails}
            id={id}
          />
        }
        <div className={styles.panelFooter}>
          {/*<Paging pagingType={pagingType} totalCount={totalCount} />*/}
        </div>
        {lastViewedEnabled && <LastViewedProducts maxSlidesToShow={xl ? 4 : 3} />}
      </div>
    </>
  );
};

Page.propTypes = pagePropTypes;

export default connect(state =>
  ({ pageTitle: state.page.metaTitle }),
  { trackProductListView },
)(withPageProps(Page));

