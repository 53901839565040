export function selectPropsFromPage({
  products,
  totalCount,
  selectedSorting,
  sortingEnabled = true,
  viewModeSwitchEnabled,
  selectedViewMode,
  pagingType,
  showThumbnails,
  lastViewedEnabled,
  relevanceAvailable,
  id,
  metaTitle,
  isItemVariantFinder,
}) {
  return {
    products,
    totalCount,
    selectedSorting,
    sortingEnabled,
    viewModeSwitchEnabled,
    selectedViewMode,
    pagingType,
    showThumbnails,
    lastViewedEnabled,
    relevanceAvailable,
    id,
    metaTitle,
    isItemVariantFinder,
  };
}
