import { PageComponentNames } from 'behavior/pages';

import { SendChallengeCode } from './sendChallengeCode';

function challengePageRenderer(page) {

  return (
    <SendChallengeCode />
  );
  
}

export default {
  [PageComponentNames.Challenge]: challengePageRenderer,
};