import { PageComponentNames } from 'behavior/pages';
import { PlaceCallOffContainer } from './PlaceCallOffContainer';


function PlaceCallOffOrderPageRenderer(page) {
  return (
    <>
    <PlaceCallOffContainer />
    </>
  );
}

export default {
  [PageComponentNames.PlaceCallOffOrder]: PlaceCallOffOrderPageRenderer,
};