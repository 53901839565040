export const AGREEMENT_APPLY = 'AGREEMENT_APPLY' as const;
export const applyAgreement = (salesAgreementId: string) => ({
  type: AGREEMENT_APPLY,
  payload: { salesAgreementId },
});

export const AGREEMENT_CANCEL = 'AGREEMENT_CANCEL' as const;
export const cancelAgreement = () => ({
  type: AGREEMENT_CANCEL,
});

export const AGREEMENT_APPLIED = 'AGREEMENT_APPLIED' as const;
export const agreementApplied = (salesAgreementId: string) => ({
  type: AGREEMENT_APPLIED,
  payload: { salesAgreementId },
});

export const AGREEMENT_CANCELED = 'AGREEMENT_CANCELED' as const;
export const agreementCanceled = () => ({
  type: AGREEMENT_CANCELED,
});

export const AGREEMENT_TERMS_APPLY = 'AGREEMENT_TERMS_APPLY' as const;
export const applyAgreementTerms = () => ({
  type: AGREEMENT_TERMS_APPLY,
});

export const CONTACTS_GET = 'SALESAGREEMENTS/CONTACTS_GET' as const;
export const getContacts = (salesAgreementId: string) => ({
  type: CONTACTS_GET,
  payload: { salesAgreementId },
});

export const CONTACTS_RECEIVED = 'SALESAGREEMENTS/CONTACTS_RECEIVED' as const;
export const contactsReceived = (contacts: any) => ({
  type: CONTACTS_RECEIVED,
  payload: { contacts },
});

export const ADD_CONTACTS = 'SALESAGREEMENTS/ADD_CONTACTS' as const;
export const addContacts = (salesAgreementId: string, contacts: any) => ({
  type: ADD_CONTACTS,
  payload: { salesAgreementId, contacts },
});

export const CONTACTS_ADDED = 'SALESAGREEMENTS/CONTACTS_ADDED' as const;
export const contactsAdded = (contacts: any) => ({
  type: CONTACTS_ADDED,
  payload: { contacts },
});

export const REMOVE_CONTACTS = 'SALESAGREEMENTS/REMOVE_CONTACTS' as const;
export const removeContacts = (salesAgreementId: string, contacts: any) => ({
  type: REMOVE_CONTACTS,
  payload: { salesAgreementId, contacts },
});

export const CONTACTS_REMOVED = 'SALESAGREEMENTS/CONTACTS_REMOVED' as const;
export const contactsRemoved = (contacts: any) => ({
  type: CONTACTS_REMOVED,
  payload: { contacts },
});

export const EDIT_CONTACT = 'SALESAGREEMENTS/EDIT_CONTACT' as const;
export const editContact = (salesAgreementId: string, contact: any) => ({
  type: EDIT_CONTACT,
  payload: { salesAgreementId, contact },
});

export const CONTACT_EDITED = 'SALESAGREEMENTS/CONTACT_EDITED' as const;
export const contactEdited = (contact: any) => ({
  type: CONTACT_EDITED,
  payload: { contact },
});

export const RESEND_NOTIFICATION = 'SALESAGREEMENTS/RESEND_NOTIFICATION' as const;
export const resendNotification = (salesAgreementId: string, contacts: any) => ({
  type: RESEND_NOTIFICATION,
  payload: { salesAgreementId, contacts },
});

export const NOTIFICATION_RESENT = 'SALESAGREEMENTS/NOTIFICATION_RESENT' as const;
export const notificationResent = () => ({
  type: NOTIFICATION_RESENT,
});

export const TOAST_SEEN = 'SALESAGREEMENTS/TOAST_SEEN' as const;
export const toastSeen = () => ({
  type: TOAST_SEEN,
});

export type SalesAgreementAction = ReturnType<
  | typeof applyAgreement
  | typeof cancelAgreement
  | typeof agreementApplied
  | typeof agreementCanceled
  | typeof applyAgreementTerms
  | typeof getContacts
  | typeof contactsReceived
  | typeof addContacts
  | typeof removeContacts
  | typeof editContact
  | typeof resendNotification
  | typeof notificationResent
  | typeof toastSeen
>;
