exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProductConfigurator_iframe{height:calc(100vh - 50px);border:none}.ProductConfigurator_configure-product-btn,.ProductConfigurator_iframe{width:100%}.ProductConfigurator_configure-product-btn-tooltip{display:block}.ProductConfigurator_configure-product-btn-tooltip:after{content:\"\";position:absolute;z-index:1;left:0;right:0;top:0;bottom:0}.ProductConfigurator_reconfigure-button{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"iframe": "ProductConfigurator_iframe",
	"configure-product-btn": "ProductConfigurator_configure-product-btn",
	"configureProductBtn": "ProductConfigurator_configure-product-btn",
	"configure-product-btn-tooltip": "ProductConfigurator_configure-product-btn-tooltip",
	"configureProductBtnTooltip": "ProductConfigurator_configure-product-btn-tooltip",
	"reconfigure-button": "ProductConfigurator_reconfigure-button",
	"reconfigureButton": "ProductConfigurator_reconfigure-button"
};