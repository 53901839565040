import { initSystemPageContent, loadSystemPageQuery } from 'behavior/pages/system';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { map } from 'rxjs/operators';

export default (routeData, _state$, { api }) => {
    return api.graphApi(loadSystemPageQuery('placeCallOffOrder')).pipe(
      map(({ pages: { placeCallOffOrder } }) => ({
        page: {
          ...placeCallOffOrder,
          component: PageComponentNames.PlaceCallOffOrder,
        },
      })),
      initSystemPageContent(),
    );
};