import styles from './ProductConfigurator.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SanaButton } from 'components/primitives/buttons';
import { requestNewProductConfiguration } from 'behavior/productConfigurator';
import Tooltip from 'components/primitives/tooltip';
import { UseSanaTexts } from 'components/sanaText';
import { generateKey } from 'utils/helpers';

const ExplicitConfigureButton = ({ 
  productConfigurator, 
  startConfiguration, 
  isConfiguratorAvailable,
  product,
  uomId,
  quantity=1,
  quantityValid=true,
  className,
 }) => {
  const [loading, setLoading] = useState(false);
  const { configuratorId, modelId } = product.productConfiguratorInfo;
  //const { quantity: { value, isValid } } = useContext(QuantityContext);

  const [componentId] = useState(generateKey);
  const [configurationFailed, setConfigurationFailed] = useState(false);

  useEffect(() => {
    if (productConfigurator
      && !productConfigurator.configuratorUrl
      && productConfigurator.updatedById === componentId) {
      setLoading(false);
      setConfigurationFailed(true);
    }
  }, [productConfigurator]);

  if (loading && productConfigurator && productConfigurator.configuratorUrl)
    setLoading(false);

  const configureProductCallback = () => {
    if (isNaN(quantity) || !quantityValid)
      return;

    //setLoading(true);
    startConfiguration({
      configuratorId,
      modelId,
      productId: product.id,
      quantity,
      uomId,
    }, componentId);
  };

  const button = (
    <SanaButton
      textKey="ConfigureProduct"
      titleTextKey={isConfiguratorAvailable && !configurationFailed ? 'ConfigureProduct' : ''}
      type="button"
      disabled={!isConfiguratorAvailable || configurationFailed}
      className={`${btnStyles.btnBig} ${styles.configureProductBtn} ${loading ? btnStyles.loading : ''} ${className}`}
      onClick={configureProductCallback}
    />
  );

  return !isConfiguratorAvailable || configurationFailed
    ? (
      <UseSanaTexts options={['ButtonAltText_DisabledConfigureProduct']}>
        {([DisabledConfigureProduct]) => (
          <Tooltip className={styles.configureProductBtnTooltip} title="" body={DisabledConfigureProduct} sign={false}>
            {button}
          </Tooltip>)}
      </UseSanaTexts>
    ) : button;
};

ExplicitConfigureButton.propTypes = {
  productConfigurator: PropTypes.shape({
    configuratorUrl: PropTypes.string,
    updatedById: PropTypes.string,
  }),
  startConfiguration: PropTypes.func.isRequired,
  isConfiguratorAvailable: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired,
  uomId: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  quantityValid: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

const mapStateToProps = state => ({ 
  isConfiguratorAvailable: state.settings.product.isConfiguratorAvailable,
});

const mapDispatchToProps = {
  startConfiguration: requestNewProductConfiguration,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExplicitConfigureButton);
