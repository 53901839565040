import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'components/primitives/table';
import { SimpleText } from 'components/sanaText';
import Step2Line from './Step2Line';
import { useMemo } from 'react';
import {removeExplodedItemsFromCallOffBodyLines} from '../../utils';
const Step2Lines = ({ lines }) => {

  const modifiedLines = useMemo(() => {

    // In case you want to display dummy/fake items
    // return removeExplodedItemsFromCallOffBodyLines(lines);

    return lines;
  }, [lines]);

  /*
  // In case you need flag to know if you will have cells that will display configuration data.
  const displayConfigurationData = useMemo(() => {
    return !!(modifiedLines.find((line) => line.product.id === 'KIT DOWNLIGHT' || line.configuratorBomNo));
  }, [modifiedLines]);

  */

  return (
    <Table className="calloff-schedule-step-2-lines">
      <thead>
        <tr>
          <th className="image-header" />
          <th className="product-id-header">
            <SimpleText textKey="ProductId" />
          </th>
          <th className="product-title-header">
            <SimpleText textKey="General_Product_Title" />
          </th>
          <th className="quantity-header">
            <SimpleText textKey="OrderedQuantity" />
          </th>
          <th className="remaining-quantity-header">
            <SimpleText textKey="RemainingQuantity" />
          </th> 
          <th className="availability-header">
            <SimpleText textKey="Availability" />
          </th>
          <th className="quantity-header">
            <SimpleText textKey="Quantity" />
          </th>
        </tr>
      </thead>
      <tbody>
        {modifiedLines.map((line,idx) => ( 
          <>
            <Step2Line key={idx} line={line} />
          </>
        ))}
      </tbody>
    </Table>
  );
};

Step2Lines.propTypes = {
  lines: PropTypes.array.isRequired,
};

export default connect (
  ({
    callOff: {
      body: {
        lines,
      },
    },
  }) => ({ lines }),
)(Step2Lines);