import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'components/primitives/grid';
import { PlaceCallOff } from './placeCallOff';
import { CallOffConfirmation } from './callOffConfirmation';
import { 
  beginCallOff,
} from 'behavior/calloff';

const PlaceCallOffContainer = () => {
  const dispatch = useDispatch();
  const [beginRequested, setBeginRequested] = useState(false);

  const { finalised } = useSelector(x => x.callOff);
	const projectId = useSelector(x => x.basket.summary.selectedProjectCode);

  const { summary } = useSelector(x => x.basket);
  const { totalCount, selectedProjectCode, expired } = summary;

  const validForNewCallOff = totalCount>0 && selectedProjectCode!=='';

  useEffect(() => {
    if (!validForNewCallOff) return;

    if (beginRequested)
    {
      setBeginRequested(false);
      return;
    }
    dispatch(beginCallOff({ projectId }));
    setBeginRequested(true);
  }, [projectId, validForNewCallOff]);

  if (expired) {
    return null;
  }

  return (
    <>
    <Container>
      {!finalised && validForNewCallOff && <PlaceCallOff /> }
      {(finalised || !validForNewCallOff) && <CallOffConfirmation />}
    </Container>
    </>
  );
}

export { PlaceCallOffContainer };
