import { ofType, combineEpics } from 'redux-observable';
import { switchMap, map, pluck } from 'rxjs/operators';
import { 
  COUNTRIES_REQUESTED,
  CREDIT_ACCOUNT_UPGRADE_REQUESTED,
  countriesReceived,
  creditAccountUpgradeDone,
} from './actions';
import { requestCountriesQuery, requestUpgradeMutation } from './queries';
import { toasts } from 'behavior/toasts';

const getCountries$ = (action$, _state$, { api }) => {
  return action$.pipe(
    ofType(COUNTRIES_REQUESTED),
    switchMap(() =>
      api.graphApi(requestCountriesQuery).pipe(
        map(d => {
          return countriesReceived(d.countries.list);
        }),
      ),
    ),
  );
};

const requestUpgrade$ = (action$, _state$, { api }) => {
  return action$.pipe(
    ofType(CREDIT_ACCOUNT_UPGRADE_REQUESTED),
    pluck('payload'),
    switchMap(( { input, files }) =>
      api.graphApi(requestUpgradeMutation, { data: input }, { files: [files], retries: 0 }).pipe(
        map(d => {
          toasts.success('', { textKey: 'CreditAccountUpgradeRequest_Created' });
          return creditAccountUpgradeDone(d);
        }),
      ),
    ),
  );
};

export default combineEpics(getCountries$, requestUpgrade$);