import styles from 'components/primitives/product/Product.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSimpleTexts } from 'components/sanaText';
import { getToLocaleDate } from 'utils/format';
import { useCultureName } from 'utils/hooks';
import useShowDueInDate from './useShowDueInDate';
import classNames from 'classnames';

const DueInDate = ({ product, className }) => {
  
  const showDueInDate = useShowDueInDate({ product });
  const culture = useCultureName();
  const formatDate = getToLocaleDate(culture);
  const { dueInDate } = product;
  const [dueInDateTemplate] = useSimpleTexts(['Product_DueIn_Template']).texts;
  const dueInDateText = (dueInDateTemplate && dueInDate) ? dueInDateTemplate.replace(/\[DUEDATE\]/g, formatDate(dueInDate)) : '';

  if (!showDueInDate) {
    return null;
  }

  const defaultComponentClassNames = classNames('product-due-in-date', styles.dueInDate, styles.outStock, styles.inline);

  const finalClassName = className || defaultComponentClassNames;
  return (
    <div className={finalClassName}>
      <span>{dueInDateText}</span>
    </div>
  );
};

DueInDate.propTypes = {
  product: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default memo(DueInDate);