import { COUNTRIES_RECEIVED } from './actions';
import { createReducer } from 'utils/redux';

export default createReducer(null, {
  [COUNTRIES_RECEIVED]: gotCountries,
});

function gotCountries(state, action) {
  const { payload: countries } = action;

  return {
    countries,
    ...state,
  };
}