import { useInsiteEditorTexts } from 'components/visualDesigner/insiteEditor';
import { useSelector } from 'react-redux';

const useEditableFacets = facets => {
  if (!useSelector(state => state.insiteEditor.initialized))
    return facets;

  const getTemplateFacet = (nameKey, optionKey) =>
    `Facet${nameKey ? `_${nameKey}` : ''}${optionKey ? `_${optionKey}` : ''}`;

  const facetsTitles = useInsiteEditorTexts(facets, (facet, getText) => {
    return { ...facet, title: getText(getTemplateFacet(facet.name), facet.title) };
  }, { isConstructedTexts: true, textOnly: true });

  const facetsValues = useInsiteEditorTexts(facets, (facet, getText) => {
    return {
      ...facet,
      values: facet.values?.map(option => ({
        ...option,
        title: getText(getTemplateFacet(facet.name, option.value), option.title),
      })),
    };
  }, { isConstructedTexts: true });

  return facets.map((facet, index) => ({ ...facet, title: facetsTitles[index].title, values: facetsValues[index].values }));
};

export default useEditableFacets;
