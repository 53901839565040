import React from 'react';
import { useEffect, useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestBasketSummary } from 'behavior/basket';
import { 
  requestDownlightDatasheetProducts,
  requestDownlightDatasheetProductsByBom,
 } from 'behavior/downlightDatasheet';
import { SimpleText } from 'components/sanaText';
import { DatasheetProductHeader } from './components';
import { default as DatasheetProduct } from './DatasheetProduct';
import styles from './DownlightDatasheet.module.scss';





const PageContent = () => {

  const [requestedProducts, setRequestedProducts] = useState(false);

  const dispatch = useDispatch();

  const { 
    basket,
    routing: {
      routeData: {
        params: {
          bomNo,
          ledNo,
        },
      },
    },
    page: {
      products,
      datasheetItems,
    },
  } = useSelector(s => s);

  useEffect(() => {
    dispatch(requestBasketSummary(true));
  }, []);

  useEffect(() => {
   if (!!datasheetItems)
   {
      dispatch(requestDownlightDatasheetProducts(datasheetItems));
   } 
  }, [datasheetItems])

  const { summary: { calculated } } = basket;

  if (!(!!calculated))
  {
    return null;
  }

  const { summary: { productLines: { list: productLines } } } = basket;

  const isDownlightPage = !!bomNo;

  const filterDownlightLines = lines => lines.filter(pl => !(!!pl.configuration) &&  pl.configuratorId===bomNo);
  const filterLedLines = lines => lines.filter(pl => !(!!pl.confifguration) && pl.ledStripOrderingId===ledNo);

  const [masterProduct] = productLines
    .filter(pl => !!pl.configuration)
    .filter(pl => isDownlightPage ? pl.configuratorId===bomNo : pl.ledStripOrderingId===ledNo)
    .map(pl => pl.configuration.products.map((e) => ({...e, ...{productLine: pl}})))
    .flat();

  if (!!masterProduct) {
    masterProduct.images = [masterProduct.image ?? { }];
  }

  const componentLines = isDownlightPage ? 
    filterDownlightLines(productLines) :
    filterLedLines(productLines);//productLines.filter(pl => pl.configuratorId===bomNo);

  const hasComponentLines = componentLines.length > 0;

  if (!requestedProducts) {
    if (hasComponentLines) {
      dispatch(requestDownlightDatasheetProducts(
        [masterProduct.id, ...componentLines.filter(pl => !!pl.product).map(pl => pl.product.id)],
      ));
    } else {
      dispatch(requestDownlightDatasheetProductsByBom({ bomNo, ledNo }));
    }
    setRequestedProducts(true);
  }

  if (!products) {
    return null;
  }

  const getProductLines = () => {
    if (!hasComponentLines) {
      const ps = datasheetItems.map(d => {
        const [product] = products.filter(p => p.id === d);
        let productLine = {};

        if (!(product.lineReference)) {
          let productLineWithReference = null;
          if (isDownlightPage) {
            productLineWithReference = productLines.find((e) => e.configuratorId === bomNo);
          } else {
            productLineWithReference = productLines.find((e) => e.ledStripOrderingId === ledNo);
          }

          productLine.lineReference = (productLineWithReference) ? productLineWithReference.lineReference : null;
        }
        const productWithLine = {...product, ...{productLine: productLine}};
        
        return productWithLine;
      });

      return ps;
    }
    const components = componentLines
      .map(pl => products.filter(p => !!pl.product && pl.product.id === p.id )
          .map((p) => ({...p, ...{productLine: {...pl}}})))
      .flat();

    const [p] = products.filter(p => p.id === masterProduct.id);
    masterProduct.productFamilyName = p?.productFamilyName;
    masterProduct.specifications = p?.specifications;
    masterProduct.images = p?.images;

    const result = !!ledNo ? [masterProduct, ...components] : components;
    
    return result;
  };

  return (
    <div className="Grid_container">
      {getProductLines().map(p => (
        <table className="datasheet-product" key={p.id}>
          <thead className={"datasheet-product-header " + styles["datasheet-product-header"]}>
            <tr>
              <th>
                <DatasheetProductHeader product={p} />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <DatasheetProduct product={p} />
              </td>
            </tr>
          </tbody>
        </table>
      ))}
      {isDownlightPage && <div className="datasheet-further-details">
        <SimpleText textKey="Datasheet_AdditionalDetails" />
      </div>}
    </div>
  );
};

export default PageContent;