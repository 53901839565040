import { useSelector } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import { Step1, Step2, Step3 } from './steps';

const CallOffDetails = ({ agreement, callOffEmail }) =>
{
  const { 
    callOff: {
      step,
    },
  } = useSelector(s => s);

  if (agreement.status==='Closed') {
    return (
      <p>
        <SimpleText textKey="ScheduleCallOffOrder_CallOffClosed" />
      </p>
    );
  }

  return (
    <>
      {step===1 && <Step1 agreement={agreement} />}
      {step===2 && <Step2 callOffEmail={callOffEmail} />}
      {step===3 && <Step3 />}
    </>
  );
};

export { CallOffDetails };