import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from '../../scheduleCallOffOrder.module.scss';
import { SimpleText } from 'components/sanaText';
import { ViewButton } from 'components/objects/productConfigurator';

const Step2SummaryLine = ({ line, documentLines }) => {

  if (!line || !documentLines) {
    return null;
  }

  const [documentLine] = documentLines.filter(docLine => docLine.id === line.id);
  
  if (!documentLine) {
    return null;
  }
  
  const {
    product: {
      id: productId,
      title: productTitle,
    },
  } = documentLine;

  const {
    quantity,
  } = line;


  let configuredItemDescription = null;
  if (documentLine.product.id === 'KIT DOWNLIGHT') {
    configuredItemDescription = (
      <div className={styles.tableConfigData}>
        <div className={styles.description}>{documentLine.description}</div>
        <div><SimpleText textKey="General_ConfigBomNo" />: <span>{documentLine.configuratorBomNo}</span></div>
        <div><SimpleText textKey="ConfiguredItem" />: <span>{documentLine.lineReference}</span></div>
        <div className={styles.lineActionsIdCell}>
          <ViewButton productConfiguration={documentLine.sanaProductConfigurationInfo}/>
        </div>
      </div>
    )
  }

  return (
    <tr className="calloff-schedule-step-2-lines">
      <td className="product-id-cell">
        <div>
          <div>{productId}</div>
          {configuredItemDescription}
        </div>
      </td>
      <td className="product-title-cell">
        {productTitle}
      </td>
      <td className="quantity-cell">
        {quantity}
      </td>
    </tr>
  );
};

Step2SummaryLine.propTypes = {
  line: PropTypes.object.isRequired,
  documentLines: PropTypes.array.isRequired,
};

export default connect(
  ({
    callOff: {
      body: {
        lines: documentLines,
      },
    },
  }) => ({ documentLines }),
)(Step2SummaryLine);