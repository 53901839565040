import { 
  DOWNLIGHT_DATASHEET_PRODUCTS_RECEIVED,
  DOWNLIGHT_DATASHEET_PRODUCTS_BY_BOM_RECEIVED,
} from './actions';

const initialState = {
  products: [],
};

export default function reducer(state = initialState, action) {
  if (action.type===DOWNLIGHT_DATASHEET_PRODUCTS_RECEIVED) {
    const { payload: { data: { catalog: { products: { products } } } } } = action;

    return {
      ...state,
      products,
    };
  }

  if (action.type===DOWNLIGHT_DATASHEET_PRODUCTS_BY_BOM_RECEIVED) {
    const { payload: { datasheet: { datasheetItems } } } = action;
    return {
      ...state,
      datasheetItems,
    };
  }

  return state;
}
