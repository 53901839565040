import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';

const ImageHelpIcon = ({ helpIconUrl }) => {

  const { texts: [altText] } = useSanaTexts([
    'HelpIcon_ImageAltText',
  ]);

  return (
    <img src={helpIconUrl} className="help-icon-image" alt={altText} />
  );
};

ImageHelpIcon.propTypes = {
  helpIconUrl: PropTypes.string.isRequired,
};

export default ImageHelpIcon;