import { PageComponentNames } from 'behavior/pages';
import { default as PageContent } from './PageContent';

function DownlightDatasheetPageRenderer(page) {
  return (
    <>
      <PageContent />
    </>
  );
}

export default {
  [PageComponentNames.DownlightDatasheet]: DownlightDatasheetPageRenderer,
};
