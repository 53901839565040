import { useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { requestNewProductConfiguration } from 'behavior/productConfigurator';
import { useProductContext } from 'components/objects/product/hooks';
import QuantityContext from 'components/objects/product/QuantityContext';
import ExplicitConfigureButton from './ExplicitConfigureButton';

const ConfigureButton = ({ 
  productConfigurator, 
  startConfiguration, 
  isConfiguratorAvailable,
  className, 
}) => {

  const { product, uomId } = useProductContext();
  const { quantity: { value, isValid } } = useContext(QuantityContext);

  return (
    <ExplicitConfigureButton 
      productConfigurator={productConfigurator}
      isConfiguratorAvailable={isConfiguratorAvailable}
      product={product}
      uomId={uomId}
      quantity={value}
      quantityValid={isValid}
      className={className}
    />
  );
};

ConfigureButton.propTypes = {
  productConfigurator: PropTypes.shape({
    configuratorUrl: PropTypes.string,
    updatedById: PropTypes.string,
  }),
  startConfiguration: PropTypes.func.isRequired,
  isConfiguratorAvailable: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

const mapStateToProps = state => ({
  productConfigurator: state.page.productConfigurator,
  isConfiguratorAvailable: state.settings.product.isConfiguratorAvailable,
});

const mapDispatchToProps = {
  startConfiguration: requestNewProductConfiguration,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureButton);
