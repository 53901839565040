import { of } from 'rxjs';
import { setPreviewToken } from './helpers';
import { reloadUser } from 'behavior/user';
import { Handler } from '../types';
import { RouteName } from 'routes';

const handler: Handler<PreviewRouteData, PreviewPage> = ({ params: { token, routeName, routeData } }, state$, { api }) => {
  if (!token)
    return of(null);

  if (state$.value.page.forward)
    return of({ forwardTo: { routeName, params: { ...routeData, previewToken: token } } });

  setPreviewToken(api, token);

  return of({ page: { forward: true }, action$: of(reloadUser()) });
};

export default handler;

type PreviewRouteData = {
  routeName: RouteName.PagePreview;
  params: {
    token?: string;
    routeName: RouteName;
    routeData: Record<string, unknown> | null;
  };
};

type PreviewPage = {
  forward: boolean;
}
