import styles from '../PLP.module.scss';
import ProductVariantLine from './ProductVariantLine';
import { ProductTrackingContext } from 'components/objects/analytics';
import PropTypes from 'prop-types';

const ProductVariantsView = ({
  productVariants,
  trackingSource,
  gridMode,
  showThumbnails,
  id,
}) => {

  if (productVariants===undefined) {
    return null;
  }

  return (
    <>
      <div className={`${gridMode ? `${styles.gridMode} ` : ''}${styles.list}`} role="list">
        {productVariants.map((productVariant, index) => (
          <ProductTrackingContext.Provider key={productVariant.code} value={{ productVariant, trackingSource }}>
            <ProductVariantLine
              productVariant={productVariant}
              gridMode={gridMode}
              showThumbnail={showThumbnails}
              index={index}
            />
          </ProductTrackingContext.Provider>
        ))}
      </div>
    </>
  );
};

ProductVariantsView.propTypes = {
  productVariants: PropTypes.array.isRequired,
  trackingSource: PropTypes.object,
  gridMode: PropTypes.bool,
  showThumbnails: PropTypes.bool,
};
export default ProductVariantsView;