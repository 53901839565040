import styles from './Facets.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { VerticalSliding } from 'components/primitives/transitions';
import { UseSanaTexts } from 'components/sanaText';
import { makeRichText, makeSimpleText } from 'utils/render';
import { Link } from 'components/primitives/links';
import { Placeholder } from 'components/primitives/placeholders';
import { CheckedIcon, MediumArrowUpIcon, MediumArrowDownIcon } from 'components/primitives/icons';
import { isBrowser } from 'utils/detections';

const minFacetValuesToShow = 5;

const textsToLoad = ['FacetedSearch_Expand', 'FacetedSearch_Collapse'];

const Facet = ({ facet, getUrl, multiSelect, isMobile }) => {
  const [expanded, setExpanded] = useState(!isMobile);
  const [collapsed, setCollapsed] = useState(true);
  const facetValuesLinkRel = !facet.crawlable ? 'nofollow' : null;
  const toggleExpand = () => setExpanded(!expanded);
  const toggleOnKey = e => {
    if (!(e.which === 32 || e.key === 'Space' || e.which === 13 || e.key === 'Enter'))
      return;

    setExpanded(!expanded);
    e.preventDefault();
  };
  const toggleCollapse = () => setCollapsed(!collapsed);
  const controlBtnPlaceholder = <Placeholder className={styles.controlBtnPlaceholder} />;

  let values = facet.values;
  if (collapsed && (isBrowser || !facet.crawlable))
    values = values.slice(0, minFacetValuesToShow);

  return (
    <section className={`${expanded ? `${styles.expanded} ` : ''}${styles.facet}`} aria-label={facet.title}>
      <div role="button" className={styles.header} onClick={toggleExpand} onKeyDown={toggleOnKey} tabIndex="0"
        aria-expanded={expanded}
      >
        <h3 className="h4">{makeSimpleText(facet.title)}</h3>
        <span className={styles.expandIcon} aria-hidden>
          {expanded ? <MediumArrowUpIcon /> : <MediumArrowDownIcon />}
        </span>
      </div>
      <VerticalSliding expanded={expanded} containerClass={styles.slider}>
        <ul className={`${collapsed ? `${styles.collapsed} ` : ''}${styles.values}`}
          aria-label={facet.title} aria-expanded={!collapsed}
          suppressHydrationWarning // It is expected that after hydration there will be only 5 items shown. Initial HTML is enough for crawlers.
        >
          {values.map((value, index) => {
            const linkData = getUrl && getUrl(facet, value);
            const valueTextTitle = value.textTitle && makeSimpleText(value.textTitle) || null;
            return (
              <li key={index} className={`${multiSelect ? '' : `${linkStyles.arrowed} `}${styles.item}`}>
                <Link
                  {...linkData}
                  className={`${multiSelect ? `${styles.multiSelect} ` : ''}${styles.itemTitle}`}
                  title={valueTextTitle}
                  aria-label={valueTextTitle}
                  rel={facetValuesLinkRel}
                >
                  {multiSelect &&
                    <span className={styles.checkbox}>
                      {value.selected &&
                        <span className={styles.checked}>
                          <CheckedIcon className={styles.icon} />
                        </span>
                      }
                    </span>
                  }
                  <span className={styles.titleText}>
                    {makeRichText(value.title)}
                  </span>
                </Link>
                {!value.selected && <span className={styles.itemCount}>({value.count})</span>}
              </li>
            );
          })}
        </ul>
        {facet.values.length > minFacetValuesToShow &&
          <UseSanaTexts options={textsToLoad} placeholder={controlBtnPlaceholder}>
            {([showAllLabel, collapseLabel]) => (
              <button
                onClick={toggleCollapse}
                className={`${linkStyles.link} ${styles.controlBtn} ${collapsed ? 'expand' : 'collapse'}`}
                aria-expanded={!collapsed}
              >
                {makeSimpleText(collapsed ? showAllLabel : collapseLabel)}
              </button>
            )}
          </UseSanaTexts>}
      </VerticalSliding>
    </section>
  );
};

Facet.propTypes = {
  facet: PropTypes.object,
  isMobile: PropTypes.bool,
  getUrl: PropTypes.func.isRequired,
  multiSelect: PropTypes.bool.isRequired,
};

export default memo(Facet);
