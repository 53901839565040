import PropTypes from 'prop-types';
import styles from '../DownlightDatasheet.module.scss';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';


function isEmptyString(str){
  return typeof str !== "string" || str.trim() === "";
}
const ProductTitle = ({ product }) => {

  const { id, title, productFamilyName, productLine} = product || {};

  if (!id && !title)
    return null;

  const namePlaceholder = <Placeholder className={styles.namePlaceholder} />;
  return (
    <div className={`${styles.overview} Details_overview`}>
      <h1 className={`${styles.productTitle} Details_product-title`}>{title}</h1>
      {/* In case of wanting for the names and values being aligned.
      <table className={styles.subDetailsTable}>
        <tr>
          <td>
            <div className={`${styles.productId} Details_product-id`}>
              <span className={`${styles.name} Details_name`}>
                <SimpleText textKey="General_Product_Id" placeholder={namePlaceholder} />
              </span>
            </div>
          </td>
          <td>
            <div className={`${styles.productId} Details_product-id`}>
              <span className={`${styles.value} Details_value`}>{productFamilyName} - {id}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className={`${styles.productId} Details_product-id`}>
                <span className={`${styles.name} Details_name`}>
                  <SimpleText textKey="ReferenceNumber" placeholder={namePlaceholder} />
                </span>
              </div>
            </td>
          <td>
            <div className={`${styles.productId} Details_product-id`}>
              <span className={`${styles.value} Details_value`}>{productLine.lineReference}</span>
            </div>
          </td>
        </tr>
      </table> */}
      <div className={`${styles.productId} Details_product-id`}>
        <span className={`${styles.name} Details_name`}>
          <SimpleText textKey="General_Product_Id" placeholder={namePlaceholder} />
        </span>
        <span className={`${styles.value} Details_value`}>
          {
            (!isEmptyString(productFamilyName)) &&
            (<span>{productFamilyName} - </span>)
          }
          {id}
        </span>
      </div>
      {
        (productLine) &&
        (<div className={`${styles.productLineReference} Details_product-line-reference`}>
        <span className={`${styles.name} Details_name`}>
          <SimpleText textKey="ReferenceNumber" placeholder={namePlaceholder} />
        </span>
        <span className={`${styles.value} Details_value`}>{productLine.lineReference}</span>
      </div>)
      }

      
    </div>
  );
};

ProductTitle.propTypes = { 
  product: PropTypes.object.isRequired,
};

export default ProductTitle;