import dateOnly from 'date-only';
import PropTypes from 'prop-types';
import { useDispatch, connect } from 'react-redux';
import { submitCallOffScheduleStep1 } from 'behavior/calloff';
import { SanaForm, FormGroup } from 'components/objects/forms';
import { TextBoxField, DatePickerField } from 'components/objects/forms';
import { SanaButton } from 'components/primitives/buttons';
import { Col, Row } from 'components/primitives/grid';
import styles from 'components/objects/profile/salesAgreements/SalesAgreements.module.scss';
import { useGetHeaderTexts } from './hooks';

const Step1 = ({ 
    agreement, 
    reference, 
    shipmentDate, 
    authCode, 
    email,
    callOffId,
   }) => {

  const dispatch = useDispatch();

  const {
    orderNoTitle,
    projectCodeTitle,
    referenceTitle,
    shipmentDateTitle,
    projectNameTitle,
  } = useGetHeaderTexts();
  
  const headerDetails = {
    orderNo: agreement.id,
    projectCode: agreement.projectCode,
    reference,
    shipmentDate,
    todayCallOffEarliestRequestDeliveryDate: agreement.todayCallOffEarliestRequestDeliveryDate
  };

  const handleNextStepClick = x => {
    const { reference, shipmentDate } = x;
    dispatch(submitCallOffScheduleStep1({ reference, shipmentDate, authCode, email, callOffId }));
  };

  return (
    <div className="calloff-schedule-step-1">
      <h3>{projectNameTitle}: {headerDetails.projectCode} - {agreement.projectName}</h3>
      <h3>{orderNoTitle}: {headerDetails.orderNo}</h3>
      <Row className={styles.details}>
        <Col xs={12} sm={8} xl={7}>
          <SanaForm 
            name="AgreementHeader"
            initialValues={headerDetails}
            onSubmit={handleNextStepClick}
          >
            <FormGroup
              fieldName="orderNo"
              fieldTitle={orderNoTitle}
              fieldComponent={TextBoxField}
              maxLength={50}
              className="calloff-schedule-hidden-field"
              readOnly
            />
            <FormGroup
              fieldName="projectCode"
              fieldTitle={projectCodeTitle}
              fieldComponent={TextBoxField}
              maxLength={50}
              className="calloff-schedule-hidden-field"
              readOnly
            />
            <FormGroup
              fieldName="reference"
              fieldTitle={referenceTitle}
              fieldComponent={TextBoxField}
              maxLength={50}
              validation={{ required: true }}
            />
            <FormGroup
              fieldName="shipmentDate"
              fieldTitle={shipmentDateTitle}
              fieldComponent={DatePickerField}
              minDate={(headerDetails.todayCallOffEarliestRequestDeliveryDate) ? 
                dateOnly.parse(headerDetails.todayCallOffEarliestRequestDeliveryDate) : dateOnly.today()}
              validation={{ required: true }}
            />
            <SanaButton
              textKey="Next"
            />
          </SanaForm>
        </Col>
      </Row>
    </div>
  );
};

Step1.propTypes = {
  agreement: PropTypes.object.isRequired,
  reference: PropTypes.string,
  shipmentDate: PropTypes.string,
  authCode: PropTypes.string,
  email: PropTypes.string,
};

export default connect(
  ({
    callOff: {
      reference,
      shipmentDate,
      authCode,
      email,
    },
    page: {
      orderId: callOffId
    }
  }) => ({ reference, shipmentDate, authCode, email, callOffId }))(Step1);