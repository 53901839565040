import PropTypes from 'prop-types';
import { memo } from 'react';
import { ProductStock } from 'components/primitives/product';

const Step2Stock = ({ product }) => {
  const { inventory, stockLevels, routingNo } = product;

  return ( 
    <ProductStock inventory={inventory} stockLevels={stockLevels} routingNo={routingNo} product={product} />
  );
};

Step2Stock.propTypes = {
  product: PropTypes.object.isRequired,
};

export default memo(Step2Stock);